
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, Container } from "react";

import { ToastContainer } from "react-toastify";
import Spinners from "../../components/Common/Spinner";
import { useNavigate, useLocation } from 'react-router-dom'

import { useSelector, useDispatch } from "react-redux";
import Pie from "components/Common/piechart";
import './test.css'
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge, CardHeader, UncontrolledAccordion, UncontrolledCollapse, AccordionBody, AccordionHeader, AccordionItem, Progress } from "reactstrap";
import './test.css'

import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"

const Summary = () => {
  const navigate = useNavigate();
  const [examName, setExamName] = useState("TNPSC - GROUP 2 ")
  const [langName, setLangName] = useState("English")
  const [examLevel, setExamLevel] = useState("Advanced")
  const [totalQuestion, setTotalQuestion] = useState("0")
  const [totalMarks, setTotalMarks] = useState("0")
  const [duration, setDuration] = useState("0")
  const [correctAnsweredCount, setCorrectAnsweredCount] = useState(0)
  const [notAnsweredCount, setNotAnsweredCount] = useState(0)
  const [wrongAnsweredCount, setWrongAnsweredCount] = useState(0)
  const [examDetails, setExamDetails] = useState([])
  const [markObtained, setMarkObtained] = useState(0)
  const [subjectWiseDetails, setSubjectWiseDetails] = useState([])
  const [reportoverallPerformance, setReportOverallPerformance] = useState([])
  const [testID, setTestID] = useState(0)
  const [testtype, setTestType] = useState(0)
  const locatioData = useLocation();
  useEffect(() => {
    if (locatioData?.state) {
      if (locatioData?.state?.testid) {
        setTestID(locatioData?.state?.testid)
      }
    }
    if (locatioData?.state?.testid) {

      bindExamAnalysisDetails(locatioData?.state?.testid)
    } else {
      navigate('/dashboard')
    }
  }, [])
  useEffect(() => {

    const disableBackButton = (e) => {
      e.preventDefault(); // Prevent default behavior of back button
      window.history.forward(); // Move forward in history
    };

    // Push a new state to prevent going back
    window.history.pushState(null, null, window.location.pathname);

    // Add an event listener for the popstate event
    window.addEventListener('popstate', disableBackButton);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);

  async function bindExamAnalysisDetails(test_ID) {
    const user = getLoggedInUser()

    const data = {
      // "stid": 50,
      "stid": Number(user.UserInfo.stid),
      // "testid": 264
      "testid": Number(test_ID)
    };

    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'exam/getExamAnalysisDetails', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await bindExamAnalysisDetails(test_ID);
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        var edList = response?.data?.body?.stu_exam_details;
        setExamDetails(edList)

        setExamName(edList[0].exam)
        setLangName(edList[0].languagename)
        setExamLevel(edList[0].examlevel)
        setTotalQuestion(edList[0].totalquestions)
        setTotalMarks(edList[0].totalmarks)
        setDuration(edList[0].duration)
        setCorrectAnsweredCount(edList[0].correctanswered)
        setNotAnsweredCount(edList[0].unanswered)
        setWrongAnsweredCount(edList[0].wronganswer)
        setMarkObtained(edList[0].marksobtained)
        setTestType(edList[0].test_type)
        // var array = [{
        //   "categoryflag": 1,
        //   "s_name": "General English",
        //   "subjectid": "148",
        //   "topicid": 0,
        //   "topicname": "",
        //   "passper": "25",
        //   "wrongper": "75"
        // },
        // {
        //   "categoryflag": 2,
        //   "s_name": "General English",
        //   "subjectid": "148",
        //   "topicid": 296,
        //   "topicname": "Scientific Knowledge and Scientific Temper",
        //   "passper": "25",
        //   "wrongper": "75"
        // }]
        // var sub_details = [...response?.data?.body?.stu_sub_details, ...array];
        var sub_details = response?.data?.body?.stu_sub_details;
        var overall_performance = response?.data?.body?.overallperformance;
        var subObj = {}
        var subjectresult = sub_details.reduce(function (r, el) {
          var subjectid = el.subjectid;
          if (!subObj[subjectid]) {
            if (Number(el.topicid) === 0) {
              subObj[subjectid] = {
                subjectid: el.subjectid,
                s_name: el.s_name,
                topicid: el.topicid,
                topicname: el.topicname,
                passper: el.passper,
                wrongper: el.wrongper,
                topicList: []
              }
            }
            r.push(subObj[subjectid]);
          }
          if (Number(el.topicid) !== 0) {
            var obj = {
              subjectid: el.subjectid,
              s_name: el.s_name,
              topicid: el.topicid,
              topicname: el.topicname,
              passper: el.passper,
              wrongper: el.wrongper,
            }
            subObj[subjectid].topicList.push(obj);
          }

          return r;
        }, [])

        setSubjectWiseDetails(subjectresult)
        setReportOverallPerformance(overall_performance)
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      //console.error("Change Password API Error:", error);
    } finally {

    }
  }
  const btnOnClick = () => {
    navigate('/reviewtest', { state: { id: Number(testID) } })
  }
  const btnOnClickHome = () => {
    navigate('/dashboard')
  }
  return (
    <React.Fragment>
      <Row className="acCard">
        <Col lg="12">
          <Card className="cardContent">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '16px', paddingTop: "16px" }}>

              <h5 className="mt-2">{examName} | {langName} | {examLevel}</h5>
              <div>

                <h8 style={{ marginTop: '5px' }}>
                  No. of Questions:
                  <span style={{ marginLeft: '5px' }}>
                    <Badge className="btn btn-blue custom-badge" style={{ marginRight: '10px', borderRadius: '53px' }}>
                      <b>{totalQuestion}</b>
                    </Badge>
                  </span>
                </h8>
                <h8 style={{ marginTop: '10px' }}>Total Marks:
                  <span style={{ marginLeft: '5px' }}>
                    <Badge className="btn btn-success custom-badge" style={{ marginRight: '10px', borderRadius: '53px' }}>
                      <b>{totalMarks}</b>
                    </Badge>
                  </span>
                </h8>
                <h8 style={{ marginTop: '10px' }}>Duration:
                  <span style={{ marginLeft: '5px' }}>
                    <Badge className="btn btn-purple custom-badge" style={{ marginRight: '10px', borderRadius: '53px' }}>
                      <b >{duration}</b>
                    </Badge>
                  </span>
                </h8>
              </div>
            </div>
            <hr />

            <Col>
              <Row>
                <Col lg="8">
                  <Row>
                    <div className="widget">
                      <div className="d-flex flex-wrap gap-2">
                        <button type="button" className="btn btn-soft-primary waves-effect waves-light">
                          Total Question <Badge className="bg-primary ms-1">{totalQuestion}</Badge>
                        </button>
                        <button type="button" className="btn btn-soft-success waves-effect waves-light">
                          Correct Answers <span className="badge bg-success ms-1">{correctAnsweredCount}</span>
                        </button>
                        <button type="button" className="btn btn-soft-warning waves-effect waves-light">
                          Not Answered Questions <Badge className="bg-warning ms-1">{notAnsweredCount}</Badge>
                        </button>
                        <button type="button" className="btn btn-soft-danger waves-effect waves-light">
                          Wrong Answers <span className="badge bg-danger ms-1">{wrongAnsweredCount}</span>
                        </button>
                        <button type="button" className="btn btn-soft-info waves-effect waves-light">
                          Marks Obtained <span className="badge bg-info ms-1 p-2 markbadgesize">{markObtained}</span>
                        </button>
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col lg="4" >
                  <div className="reviewbtn">
                  <div className="d-sm-flex align-items-center justify-content-end">
                  <Button type="button" color="primary " onClick={() => { btnOnClickHome() }}>
                                                  {/* <i className="dripicons-preview font-size-15 align-middle me-2"></i> */}
                                                  {/* <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i> */}
                                                  {" "} Back to home
                                                </Button>
                                                </div>

                    {/* <div className="d-sm-flex align-items-center justify-content-end">
                      <Button type="button" color="primary " onClick={() => { btnOnClick() }}>
                        <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i>{" "}
                        Review
                      </Button>
                    </div> */}
                  </div>
                  <div className="reviewbtn">
                  <div className="d-sm-flex align-items-center justify-content-end">
                  <Button type="button" color="primary " onClick={() => { btnOnClick() }}>
                                                  <i className="dripicons-preview font-size-14 align-middle me-2"></i>
                                                  {/* <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i> */}
                                                  {" "} Review
                                                </Button>
                                                </div>

                    {/* <div className="d-sm-flex align-items-center justify-content-end">
                      <Button type="button" color="primary " onClick={() => { btnOnClick() }}>
                        <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i>{" "}
                        Review
                      </Button>
                    </div> */}
                  </div>
                </Col>
              </Row>
              <Row>

                <Col lg="6">
                  <Card className="PieCard">
                    <h4 className="heading"> OverView</h4>
                    <div id="pie-chart" className="e-chart">
                      <Pie dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]' examDetails={examDetails} />
                    </div>

                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="performenceCard">
                
<Row>
  <Col lg={7}>

                                 
                                 <h4 style={{padding: '16px'}}>Performance</h4>   
                                 
                                      </Col>

{testtype == 2?
<Col lg={4}>
                                  <span className="accordprogress1" >

                                    <Progress multi>
                                      <Progress bar color="success" value={Number(reportoverallPerformance[0]?.passper)}></Progress>

                                      <Progress bar color="danger" value={Number(reportoverallPerformance[0]?.wrongper)}></Progress>

                                    </Progress>
                                    <Row className="pt-1">
                                      {reportoverallPerformance[0]?.passper !== '0.00' && <div style={{ width: "50%" }}><p className="font-size-10">{reportoverallPerformance[0]?.passper}%</p></div>}
                                      {reportoverallPerformance[0]?.wrongper !== '0.00' && <div style={{ width: "50%" }}><p className="font-size-10">{reportoverallPerformance[0]?.wrongper}%</p></div>}
                                    </Row>

                                 
                                  </span>
</Col>
                                  : "" }
                                  
                                  </Row>

                                  {/* </span> */}
                                {/* </div> */}
                              

                    <div className="mt-2">

                      <UncontrolledAccordion flush>
                        {
                          (subjectWiseDetails || []).map((item, index) => (
                            <AccordionItem key={index}>
                              <AccordionHeader targetId={item.subjectid}>
                                <div className="wholeaccord">
                                  <span className="accorhead"  >

                                    {item.s_name}  
                                   


                                  </span>
                                  <span className="accordprogress" >

                                    <Progress multi>
                                      <Progress bar color="success" value={Number(item.passper)}></Progress>

                                      <Progress bar color="danger" value={Number(item.wrongper)}></Progress>

                                    </Progress>
                                    <Row className="pt-1">
                                      {item.passper !== '0.00' && <div style={{ width: "50%" }}><p className="font-size-10">{item.passper}%</p></div>}
                                      {item.wrongper !== '0.00' && <div style={{ width: "50%" }}><p className="font-size-10">{item.wrongper}%</p></div>}
                                    </Row>

                                    {/* <Progress multi>
                                      <Progress bar color="" value={Number(item.passper)}>{item.passper}%</Progress>
                                      <Progress bar color="" value={Number(item.wrongper)}>{item.wrongper}%</Progress>
                                      
                                    </Progress> */}
                                  </span>
                                </div>
                              </AccordionHeader>
                              <AccordionBody accordionId={item.subjectid}>
                                {
                                  (item.topicList || [])?.map((t, index1) => (

                                    <Row className="m-2" key={index1} >
                                      <Col xs="8">
                                        <span className="accorhead"  >
                                          {t.topicname}
                                        </span>
                                      </Col>
                                      <Col xs="4">
                                        <span className="accordprogress prog" >

                                          {/* <Progress multi>
                                            <Progress bar color="success" value={Number(t.passper)}>{t.passper}%</Progress>
                                            <Progress bar color="danger" value={Number(t.wrongper)}>{t.wrongper}%</Progress>
                                          </Progress> */}
                                          <Progress multi>
                                            <Progress bar color="success" value={Number(t.passper)}></Progress>
                                            <Progress bar color="danger" value={Number(t.wrongper)}></Progress>
                                          </Progress>
                                          <Row className="pt-1">
                                            {item.passper !== '0.00' && <div style={{ width: "50%" }}><p className="font-size-10">{item.passper}%</p></div>}
                                            {item.wrongper !== '0.00' && <div style={{ width: "50%" }}><p className="font-size-10">{item.wrongper}%</p></div>}
                                          </Row>
                                        </span>
                                      </Col>

                                    </Row>
                                  )
                                  )
                                }

                              </AccordionBody>
                            </AccordionItem>
                          ))
                        }


                      </UncontrolledAccordion>
                    </div>
                  </Card>

                </Col>


              </Row>
            </Col>






          </Card>
        </Col>

      </Row>
    </React.Fragment>
  );
}
Summary.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Summary;
