import React, { useState } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/logo.svg";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/trios-logo.jpg";
import { withTranslation } from "react-i18next";
import * as url from "../../../src/helpers/url_helper";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import '../../assets/customstyle.css'

const Header = props => {
  const user = getLoggedInUser()
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // State to manage mobile menu visibility
  const userStudentMaterial = user.UserInfo.stu_interested.filter((e) => e.mstid === 1).length > 0 ? true : false;
  const userOnlineTest = user.UserInfo.stu_interested.filter((e) => e.mstid === 2).length > 0 ? true : false;

  const onClickDrop = (from) => {
    if(from === 'Subject')
      navigate("/subjectwisemocktest");
    else if (from === 'Topic')
      navigate("/Topicwisemocktest");
    else if (from === 'Model')
      navigate("/fullLengthtest");

    setMenuOpen(false); // Close the dropdown after clicking an item
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle mobile menu
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="37" />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="19" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              onClick={toggleMenu} 
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <Link to="/dashboard" className="nav-link dropdown-toggle arrow-none" style={{ marginTop: '30px' }}>
              <i className="bx bx-home-circle me-2"></i>
              {props.t("Home")} {props.menuOpen}
            </Link>

            {userStudentMaterial && 
              <Link to="/studymaterials" className="nav-link dropdown-toggle arrow-none" style={{ marginTop: '30px', marginLeft: '30px' }}>
                <i className="bx bxs-detail me-2"></i>
                {props.t("Study Material")} {props.menuOpen}
              </Link>
            }

            {userOnlineTest && 
              <Dropdown
                className="nav-link dropdown-toggle arrow-none"
                style={{ marginTop: '5px' }}
                isOpen={menuOpen} 
                toggle={toggleMenu} 
              >
                
                <DropdownToggle className="btn header-item " caret tag="button">
                  <i className="bx bx-home-circle me-2"></i>
                  {props.t("Online Test")} <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-megamenu">
              
                  <div className="dropdown-item subjectwisedropdown cursor-focus" onClick={() => onClickDrop('Subject')}>
                    {props.t("Subject Wise Mockup Test")}
                  </div>
                  <div className="dropdown-item subjectwisedropdown cursor-focus" onClick={() => onClickDrop('Topic')}>
                    {props.t("Topic Wise Mockup Test")}
                  </div>
                  <div className="dropdown-item subjectwisedropdown cursor-focus" onClick={() => onClickDrop('Model')}>
                    {props.t("Model Exam")}
                  </div>
                </DropdownMenu>
              </Dropdown>
            }
          </div>

          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default

 connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));