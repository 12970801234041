import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper"; 
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
// import images
import logodark from "../../assets/images/trios-logo.jpg";
import logolight from "../../assets/images/trios-logo.jpg";
import CarouselPage from "./CarouselPaage";
import { loginUser, socialLogin } from "../../store/actions";
import withRouter from "components/Common/withRouter";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [configuration, setConfiguration] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);

  document.title = "Login  | TNPSC";

  useEffect(() => {
    localStorage.removeItem("authUser")
  },[])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: (configuration && configuration.username) || "",
      password: (configuration && configuration.password) || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email/mobileno"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      if(1!=1)
     // if(recaptcha == ""  || recaptcha == null || recaptcha == undefined)
      {
        toast.error("Please verify the captcha" ,{ position: toast.POSITION.TOP_RIGHT });
        return false
      }
      try {
        const data = {  
          username: values.email,
          password: values.password
        };
        const response = await axios.post(url.BASE_URL + 'user/login', data); 
    
        if (response.data.body.statusFlag === 1) {
          localStorage.setItem("authUser", JSON.stringify(response.data.body));
          navigate('/dashboard');
        } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
          // toast.error(response.data.body.message, { autoClose: 2000 });
        }
      } catch (error) {
        console.error("Login API Error:", error);
      }
    }
  });

  
  // const validation = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //      email: '',
  //     password: '',
  //   },
  //   validationSchema: Yup.object({
  //     //  email: Yup.string().required("Please Enter Your Email"),
  //     // password: Yup.string()
  //     //   .required("Please Enter Your Password at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.")
  //     //   .matches(
  //     //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
  //     //     "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
  //     //   ),
  //   }),
  //   onSubmit: async (values) => {
  //     try {
  //       const data = {  
  //         username: values.email,
  //         password: values.password
  //       };
  //       const response = await axios.post(url.BASE_URL + 'user/login', data); 
    
  //       if (response.data.body.statusFlag === 1) {
  //         localStorage.setItem("authUser", JSON.stringify(response.data.body));
  //         navigate('/dashboard');
  //       } else {
  //         toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
  //         // toast.error(response.data.body.message, { autoClose: 2000 });
  //       }
  //     } catch (error) {
  //       console.error("Login API Error:", error);
  //     }
  //   }
  // });

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4" style={{ backgroundColor: '#FFFFFF' }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-2 mb-md-5">
                      <Link to="/dashboard" className="d-block card-logo">

                        <img
                          src={logolight}
                          alt=""
                          height="45"
                          width="85"
                          className="logo-dark-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary text-center" >Sign In</h5>

                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email/MobileNo.
                            <span style={{ color: 'red' }}>*</span></Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email/mobileno."
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                              maxLength={30}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password<span style={{ color: 'red' }}>*</span></Label>
                            <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              // type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              maxLength={30} 
                              invalid={validation.touched.password && !!validation.errors.password}
                            />
                                <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                                </button>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}

                            </div>
                            </div>
                           <ReCAPTCHA
                            sitekey="6Ld2zwoqAAAAAOlQb9aeAUs7FXqAYs4vzU3_LJUk"
                            onChange={(value) => {
                              console.log("reCAPTCHA value:", value);
                              setRecaptcha(value)

                            }}
                          /> 
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                          Sign In
                            </button>
                          </div>
                          <div className="mt-3 text-center">
                            <p>
                              Don&#39;t have an account ?{" "} <Link to="/register" className="fw-medium text-primary">
                                {" "}
                                Signup now{" "}
                              </Link>{" "}
                            </p>

                          </div>

                          <div className="mt-4 text-center">
                            {/* <Link to="/forgot-password" className="text-muted"> */}
                              <i className="mdi mdi-lock  me-1" />
                              <Link to="/forgot-password" className="fw-medium text-primary">
                                {" "}
                                Forgot your password?{" "}
                              </Link>{" "}
                              {/* Forgot your password? */}
                            {/* </Link> */}
                          </div>


                        </Form>


                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                      All rights reserved. © {new Date().getFullYear()}. <br/>Powered
                        by
                        <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};