import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// import {
//   Button,
//   Card,
//   Col,

//   Row,
//   Nav,

//   NavItem,

// } from "reactstrap";
import {
  Table,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Collapse
} from "reactstrap";
import layout2 from "../../assets/images/nodatafound.jpg";

import Spinners from "../../components/Common/Spinner";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import classnames from "classnames";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Paginations from '../../components/Common/Pagination';

import './Subject.scss'
import '../../assets/customstyle.css';
import { result } from "lodash";
const SubjectContent = () => {
  //meta title
  document.title = "StudyMaterials | ICE - Student Portal";
  const data = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [Subject, setSubject] = useState([]);
  const [Subjectsidecard, setSubjectsidecard] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeCategoryID, setActiveCategoryID] = useState(0);
  // pagination
  // const [jobGridData, setJobGridData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 8;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;
  const currentData = useMemo(() => Subject?.slice(indexOfFirst, indexOfLast), [Subject, indexOfFirst, indexOfLast])

  const navigate = useNavigate();
  const [activeSubjectID, setActiveSubjectID] = useState(0);


  const [activeTab, setActiveTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [badgeLength, setBadgeLength] = useState();
  const [isOpen, setIsOpen] = useState(true)
  const [activeGroupName, setActiveGroupName] = useState("");
  const [activeSubjectName, setActiveSubjectName] = useState("");
  const [statusFlag, setStatusFlag] = useState(0);
  const [activeSubjectTab, setActiveSubjectTab] = useState("1");
  const [subjectid, setSubjectid] = useState("");
  const [stexctid, setexctid]= useState("")
  const toggle = () => setIsOpen(!isOpen);



  const handlesubjectchange = async (params) => {
    // setLoading(true)

    const user = getLoggedInUser();
    const data = {
      stid: Number(user.UserInfo.stid),
      mk_exctid: Number(params.mk_exctid),
      sid: Number(params.sid)
    };
    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/getMockUpTestInstructions', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await handlesubjectchange(mk_exctid);
          }
        }
      }

      if (response?.data?.body?.stu_examdetailslist) {
        setSubject(response.data.body.stu_examdetailslist);
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student details API Error:", error);
    }
  };
  
  useEffect(()=> {
if(data?.state){
  if(data?.state?.exctid){
    setexctid(data?.state?.exctid)
  }
  if(data?.state?.subjectid){
    setSubjectid(data?.state?.subjectid)
  }
  if(data?.state?.statusFlag){
    setStatusFlag(data?.state?.statusFlag)
  }
}
  }, [data])

  const handleCardClick = (mk_exctid, index) => {
    handlesubjectchange(mk_exctid);
    setClickedIndex(index);
  };

  const getMenuContent = (item, index) => {
    setActiveCategoryID(item.mk_exctid)
    setActiveTab(index)
    setActiveSubjectTab(index)
    setActiveGroupName(item.examboardcategory);
    setActiveSubjectName(item.exp_name);
    setActiveSubjectID(item.sid)
  }
  const onClickBoardCategory = (row) => {
    if (Subjectsidecard && Subjectsidecard.length > 0) {
      const boardCategoryList_arr = Object.assign([], Subjectsidecard)
      const result = []
      boardCategoryList_arr.forEach(i => {
        const obj = { ...i }
        if (i.mk_exctid === row.mk_exctid) {
          if (row.isopen) {
            obj['isopen'] = false
          } else {
            obj['isopen'] = true
          }
        } else {
          obj['isopen'] = false
        }
        result.push(obj)
        setSubjectsidecard(result)
      })
    }
  }



  const onClickExamPaper = (row) => {
    if (Subjectsidecard && Subjectsidecard.length > 0) {
      const boardCategoryList_arr = Object.assign([], Subjectsidecard)
      const result = []
      const subresult = []
      boardCategoryList_arr.forEach(i => {
        const examPaperLisArr = Object.assign([], i.exampaper)
        const result1 = []
        const obj = { ...i }
        examPaperLisArr.forEach(j => {

          const obj1 = { ...j }
          if (j.mk_exctid === row.mk_exctid && j.mk_expid === row.mk_expid) {
            if (j.isopen) {
              obj1['isopen'] = false
            } else {
              obj1['isopen'] = true
              //   setActivePaperName(row.exp_name);
              setActiveSubjectName(row.subjectList[0].s_name);
            }
          }
          result1.push(obj1)
          subresult.push(obj1)
        })
        obj['exampaper'] = result1
        result.push(obj)
        setSubjectsidecard(result)
      })
    }
  }
  // useEffect(() => {
  //     setJobGridData(currentData);
  // }, [currentData]);

  const colorArray = [
    { "badge_color": "text-bg-info", "bg_color": "btn-soft-info" },
    { "badge_color": "text-bg-warning", "bg_color": "btn-soft-warning" },
    { "badge_color": "text-bg-success", "bg_color": "btn-soft-success" },
    { "badge_color": "text-bg-secondary", "bg_color": "btn-soft-secondary" },
    { "badge_color": "text-bg-primary", "bg_color": "btn-soft-primary" },
    { "badge_color": "text-bg-dark", "bg_color": "btn-soft-dark" },
  ]





  useEffect(() => {
    if (Subject && Subject.length > 0) {
      setActiveTab(0)
      setActiveGroupName(Subject[0].mk_testname);
    }
  }, [Subject]);

  useEffect(() => {
    if (Subject && Subject.length > 0) {
      setActiveTab(0)
      setActiveGroupName(Subject[0].mk_duration);
    }
  }, [Subject]);

  useEffect(() => {
    if (Subject && Subject.length > 0) {
      setActiveSubjectTab(0)
      setActiveSubjectName(Subject[0].mk_totalmarks);
    }
  }, [Subject]);

  useEffect(() => {
    if (Subject && Subject.length > 0) {
      setActiveSubjectTab(0)
      setActiveSubjectName(Subject[0].mk_totalquestions);
    }
  }, [Subject]);

  useEffect(() => {
    if (Subject && Subject.length > 0) {
      setActiveSubjectTab(0)
      setActiveSubjectName(Subject[0].mk_totalmarks);
    }
  }, [Subject]);




  useEffect(() => {

    const data = localStorage.getItem('authUser')
    const parsevalue = JSON.parse(localStorage.getItem('authUser'))
    if (parsevalue?.UserInfo?.stu_interested[0]?.mstid == 2) {
      handlesubjectsidechange();

    } else if (parsevalue?.UserInfo?.stu_interested[0]?.mstid == 1 && parsevalue?.UserInfo?.stu_interested[1]?.mstid == 2) {
      handlesubjectsidechange();

    } else if (parsevalue?.UserInfo?.stu_interested[1]?.mstid == undefined || parsevalue?.UserInfo?.stu_interested[0]?.mstid != 2) {

      navigate('/dashboard')
    }

  }, []);

  function onClickSubject(r) {
    const user = getLoggedInUser()
    setIsOpen(!isOpen)

    const params = {
      "mk_exctid": Number(r.mk_exctid),
      "sid": Number(r.sid),
      "stid": Number(user.UserInfo.stid)
    }

    // setTopicList(params)
    // setParams(Number(user.UserInfo.stid))

    handlesubjectchange(params);


  }

  const handlesubjectsidechange = async () => {
    setLoading(true)
    var boardcatArr = [];

    const user = getLoggedInUser();
    const data = {
      stid: Number(user.UserInfo.stid),
    };
    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/getsubjects_topicslist', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await handlesubjectsidechange();
          }
        }
      }

      if (response?.data?.body?.exboard_excategorytopiclist) {
        boardcatArr = response.data.body.exboard_excategorytopiclist
        bindMenuArray(boardcatArr)
        // setSubjectsidecard(response.data.body.exboard_excategorytopiclist);
        if (response?.data?.body?.exboard_excategorytopiclist.length > 0) {

          setActiveCategoryID(response.data.body.exboard_excategorytopiclist[0].mk_exctid)

          const params = {
            "sid": Number(response.data.body.exboard_excategorytopiclist[0].sid),
            "mk_exctid": Number(response.data.body.exboard_excategorytopiclist[0].mk_exctid),
            "stid": Number(user.UserInfo.stid)
          }

          await handlesubjectchange(params);
        }
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student detailsAPI getsubjects_topicslist Error:", error);
    }
  };

  const bindMenuArray = (boardcatArr) => {
    const user = getLoggedInUser()
    if (boardcatArr && boardcatArr.length > 0) {
      let params;
      setActiveTab("1")
      var categoryObj = {}
      var result = boardcatArr.reduce(function (r, el) {
        var mk_exctid = el.mk_exctid;
        if (!categoryObj[mk_exctid]) {
          categoryObj[mk_exctid] = {
            mk_exctid: el.mk_exctid,
            examboardcategory: el.examboardcategory,
            isopen: false,
            exampaper: []
          }
          r.push(categoryObj[mk_exctid]);
        }
        var obj = {
          mk_exctid: el.mk_exctid,
          mk_expid: el.mk_expid,
          exp_name: el.exp_name,
          isopen: false,
          sid: el.sid,
          // tid: el.tid,
          examboardcategory: el.examboardcategory,
          s_name: el.s_name,
          // t_count: el.t_count
        }
        categoryObj[mk_exctid].exampaper.push(obj);

        return r;
      }, [])

      var examdetailsarray = []
      for (var i = 0; i < result.length; i++) {
        var subObj = {}
        var j = 0;
        var subjectresult = result[i].exampaper.reduce(function (r, el) {
          j = j + 1;
          var mk_expid = el.mk_expid;
          if (!subObj[mk_expid]) {
            subObj[mk_expid] = {
              mk_exctid: el.mk_exctid,
              mk_expid: el.mk_expid,
              exp_name: el.exp_name,
              examboardcategory: el.examboardcategory,
              isopen: j === 1 ? true : false,
              subjectList: []
            }
            r.push(subObj[mk_expid]);
          }
          var obj = {
            mk_exctid: el.mk_exctid,
            mk_expid: el.mk_expid,
            exp_name: el.exp_name,
            isopen: j === 1 ? true : false,
            sid: el.sid,
            // tid: el.tid,
            examboardcategory: el.examboardcategory,
            exp_name: el.exp_name,
            s_name: el.s_name,
            // t_count: el.t_count
          }
          subObj[mk_expid].subjectList.push(obj);
          return r;
        }, [])
        var finalobj = {
          mk_exctid: result[i].mk_exctid,
          examboardcategory: result[i].examboardcategory,
          isopen: i == 0 ? true : false,
          exampaper: subjectresult
        }
        examdetailsarray.push(finalobj);
      }
      setSubjectsidecard(examdetailsarray)
      setActiveSubjectID(examdetailsarray[0].exampaper[0].subjectList[0].sid)
       params = {
        "sid": Number(examdetailsarray[0].exampaper[0].subjectList[0].sid),
        "mk_exctid": Number(examdetailsarray[0].exampaper[0].mk_exctid),
        "stid": Number(user.UserInfo.stid)
      }
      //   listTopicMaterialSubjectList(params)
      setActiveGroupName(examdetailsarray[0].examboardcategory);
      //   setActivePaperName(examdetailsarray[0].exampaper[0].exp_name);
      setActiveSubjectName(examdetailsarray[0].exampaper[0].subjectList[0].s_name);

      //   setLoadStatus(false)
    
    }
  }
  useEffect(()=>{
if(data?.state?.statusFlag === 1 && Subjectsidecard && Subjectsidecard.length > 0){

  const boardCategoryList_arr = Object.assign([], Subjectsidecard)
      const result = []
      boardCategoryList_arr.forEach(i => {
        const obj = { ...i }
        // if (i.mk_exctid === row.mk_exctid) {
          if (Number(i.exampaper[0].mk_exctid) === Number(data?.state?.exctid) && Number(i.exampaper[0].mk_expid) == Number(data?.state?.expid) ) {
setActiveSubjectID(data?.state?.sid)
            if (i.isopen == false) {
              obj['isopen'] = true
          } else {
            obj['isopen'] = true
          }
        } else {
          obj['isopen'] = false
        }
        result.push(obj)
        setSubjectsidecard(result)
      })




}
  },[Subjectsidecard, data?.state?.statusFlag])

  const handleClick = (item) => {
    if (item.subjectid === 3) {
      navigate("/Instructionspage", { state: { id: item.mkid, type: 0, exctid: item.mk_exctid, testname: item.mk_testname, expid: item.mk_expid, mk_sid: item.mk_sid } });
    } else {
      navigate("/Instructionspage", { state: { id: item.mkid, type: 0,exctid: item.mk_exctid, testname: item.mk_testname,  expid: item.mk_expid, mk_sid: item.mk_sid  } });
    }
  };

  const reattempt = (item) => {
    // if(item){

    navigate("/onlinetest", { state: { id: item.testid, type: 0, reattempt: 1, cid: item.mkid } });
    // }else{
    // navigate("/dashboard")
    // }


  };
  const resume = (item) => {
    // if(item){

    navigate("/onlinetest", { state: { id: item.testid, type: 0, resume: 1, cid: item.mkid } });
    // }else{
    // navigate("/dashboard")
    // }
  };

  const review = (item) => {

    navigate('/reviewtest', { state: { id: Number(item.testid) } })
  };


  return (
    <React.Fragment>


      <Row>
        {isLoading ?
          <Spinners setLoading={setLoading} /> :

          <Col lg={12} >
            {Subjectsidecard && Subjectsidecard.length > 0 ?



              <Col lg={12}>

                <Card className="email-leftbar">
                  <Button type="button" color="danger" block>Exam Board Categories</Button>
                  <div className="mail-list mt-1">
                    <ul className="list-unstyled categories-list">
                      {
                        (Subjectsidecard || []).map((item, index) => (
                          <li key={index}>
                            <div className="custom-accordion">
                              <Link
                                className="text-body fw-medium py-1 d-flex align-items-center"
                                onClick={() => {
                                  onClickBoardCategory(item)
                                }}
                                to="#"
                              >
                                <i className="bx bx-book-open smgroup me-2"></i>{item.examboardcategory}

                                <i
                                  className={
                                    item.isopen
                                      ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                      : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                  }
                                />
                              </Link>
                              <Collapse isOpen={item.isopen == true}>
                                <div className="card border-0 shadow-none ps-2 mb-0">
                                  <ul className="list-unstyled mb-0">
                                    {
                                      (item.exampaper || [])?.map((f, e_index) => (

                                        <li key={e_index}>
                                          <div className="custom-accordion">
                                            <Link
                                              className="text-body fw-medium py-1 d-flex align-items-center font-size-11"
                                              onClick={() => {
                                                setActiveTab(e_index);
                                                onClickExamPaper(f)
                                              }}
                                              to="#"
                                            >
                                              {f.exp_name}
                                              <i
                                                className={
                                                  f.isopen
                                                    ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                                    : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                }
                                              />
                                            </Link>
                                            <Collapse isOpen={f.isopen}>
                                              <div className="card border-0 shadow-none ps-2 mb-0">
                                                <ul className="list-unstyled mb-0 subject">
                                                  {
                                                    (f.subjectList || [])?.map((t, s_index) => (

                                                      // <li key={index} onClick={() => {
                                                      //   getMenuContent(t, index)
                                                      // }}>
                                                      <li className={Number(activeSubjectID) == Number(t.sid) && f.isopen ? 'subject-active' : 'subject-li'} key={s_index} onClick={() => {
                                                        getMenuContent(t, s_index)
                                                      }}>
                                                        <Link to="#" className="d-flex align-items-center font-size-11 sub_li"
                                                          onClick={() => {
                                                            onClickSubject(t)
                                                          }}
                                                        >
                                                          <span className="me-auto">{t.s_name}</span>
                                                          {t.t_count && <span className="badge bg-success rounded-pill p-1 ms-2">{t.t_count}</span>}
                                                        </Link>
                                                      </li>
                                                    ))
                                                  }
                                                </ul>
                                              </div>
                                            </Collapse>
                                          </div>
                                        </li>
                                      ))
                                    }
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>



                </Card>

                {/* <Card className="email-leftbar" style={{ height: "500px", position: "scroll" }}>
                                    <div>
                                        <Button type="button" color="danger" block>
                                            Exam Board Categories
                                        </Button>
                                        <div className="mail-list mt-2">
                                            <ul className="list-unstyled categories-list subject">
                                                {Subjectsidecard.map((item, index) => (
                                                    // <li key={index}>
                                                    <li className={activeCategoryID === item.mk_exctid ? 'subject-active' : 'subject-li'} key={index} onClick={() => {
                                                        getMenuContent(item)
                                                    }}>
                                                        <div className="custom-accordion">
                                                            <Link
                                                                style={{
                                                                    color: clickedIndex === index ? 'red' : (hoveredIndex === index ? 'red' : 'inherit')
                                                                }}
                                                                className="text-body fw-medium py-1 d-flex align-items-center"
                                                                to="#"
                                                                onClick={() => handleCardClick(item.mk_exctid, index)}
                                                            >
                                                                <i className="bx bx-book-open smgroup me-2"></i>
                                                                {item.examboardcategory}
                                                            </Link>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Card> */}
              </Col> : ""
            }
            {
              Subject && Subject.length > 0 ?

                <CardBody>

                  <Row>

                    <Col md="12" className="mainpage1">
                      <TabContent

                        className="text-muted mt-4 mt-md-0"
                      >

                        <TabPane>

                          <Row>
                            {(Subject || []).map((item, key) => (
                              <Col xl={3} md={6} key={key}>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col lg="9">

                                        <h5 className="fs-17 mb-2">
                                          <div className="text-dark">{item.mk_testname}</div> </h5>
                                      </Col>
                                      <Col lg="3">
                                        {item?.testid != null && item?.test_completed == 1 && 
                                        // <Col lg="1">
                                          <div className="reviebutton">
                                            <Button type="button" color="primary" className="revbutt" onClick={() => { review(item) }}>
                                              <i className="dripicons-preview font-size-15 align-middle me-1"></i>
                                              {/* <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i> */}
                                              {" "}

                                            </Button>

                                          </div>
                                        // </Col>
                                        }
                                      </Col>
                                    </Row>

                                    {/* <h5 className="fs-17 mb-2">
                                                                            <Link to="/Instructionspage" className="text-dark">{item.mk_testname}</Link> </h5> */}
                                    <ul className="list-inline mb-0">

                                      <li className="list-inline-item" style={{ width: '100%' }}>
                                        <Row>
                                          <Col lg="9">
                                            <p className="text-muted fs-14 mb-0"><i className="mdi mdi-clock-time-four-outline"></i> {item.mk_duration}</p>
                                          </Col>
                                          {/* {item?.testid != null && item?.test_completed == 1 && <Col lg="3">

                                                                                        <div className="reviebutton">
                                                                                            <Button type="button" color="primary" onClick={() => { review(item) }}>
                                                                                                <i className="dripicons-preview font-size-15 align-middle me-2"></i>
                                                                                         
                                                                                                {" "}
                                                                                                Review
                                                                                            </Button>

                                                                                        </div>
                                                                                       
                                                                                    </Col>} */}

                                        </Row>
                                      </li>

                                    </ul>
                                    <hr className="line3" />
                                    <div className="mt-3 hstack gap-2">

                                      <div className="col scorecolval">

                                        <div>


                                          <p className="text-muted mb-0 scorealign"><i className="fas fa-layer-group iconspace"></i>

                                            Max Score

                                          </p>
                                          <p className="text-muted mb-0 scores">

                                            <p className='cardnumber1'>
                                              {item.mk_totalmarks} </p>   </p>
                                        </div>
                                      </div>
                                      <div className="col quescolval">

                                        <div>


                                          <p className="text-muted mb-0 quesalign"><i className="dripicons-question iconspace"></i>
                                            Questions




                                          </p>

                                          <p className="text-muted mb-0 scores">

                                            <p className='cardnumber1'>
                                              {item.mk_totalquestions}  </p>   </p>
                                        </div>
                                      </div>
                                      {/* <div className="col durationcolval">
                                                                                <div className="text-right rightalign">
                                                                                    <p className="text-muted mb-0 duralign"><i className="dripicons-question iconspace"></i>Mark/Question</p>
                                                                                    <p className="text-muted mb-0 scores">

                                                                                        <p className='cardnumber1'>
                                                                                            {item.mk_totalmarks}</p>   </p>

                                                                                </div>

                                                                            </div> */}


                                    </div>
                                    {/* <div>
                                                                {item?.testid != null && item?.test_completed == 1 ? (
                                                                    <div className="btnModalDesign"><button onClick={() => reattempt(item)} className="btn btn-soft-success w-50">
                                                                        Reattempt
                                                                    </button>
                                                                        <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                                                            ReTest
                                                                        </button></div>
                                                                ) : null}

                                                                {item?.testid != null && item?.isresumed == 1 ? (



                                                                    <div className="btnModalDesign">   <button onClick={() => resume(item)} className="btn btn-soft-danger w-50">
                                                                        Resume
                                                                    </button>
                                                                        <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                                                            ReTest
                                                                        </button></div>


                                                                ) : null}

                                                                {item.testid == null ? (
                                                                    <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-100">
                                                                        Start Test
                                                                    </button>
                                                                ) : null}


                                                           
                                                            </div> */}
                                    <div>
                                      {item?.testid != null && item?.test_completed == 1 ? (
                                        <div 
                                        // className="btnModalDesign"
                                        className={item?.isnewtest == 0  ? "btnModalDesign1" : "btnModalDesign"}

                                        
                                        ><button onClick={() => reattempt(item)} 
                                        // className="btn btn-soft-success w-50"
                                        className=
                                        
                                        {item?.isnewtest == 0  ? "btn btn-soft-success w-100" : 
                                        "btn btn-soft-success w-50"
                                         }
                                        >
                                          Reattempt
                                        </button>
                                        {item?.isnewtest == 1 ?
                                          <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                            New test
                                          </button> : ""
                                        }
                                        </div>
                                      ) : null}

                                      {item?.testid != null && item?.isresumed == 1  ? (



                                        <div 
                                        className={item?.isnewtest == 0  ? "btnModalDesign1" : "btnModalDesign"}
                                        // style={{display:'flex', columnGap: '1rem'}}
                                     
                                        // xl={(refferenceArray.length > 0 && refferenceArray[0].downloadablefile == null && refferenceArray[0].downloadablefile == undefined) && (subjectList && subjectList.length == 0) ? 12 : 9}
                                        >   <button onClick={() => resume(item)} className=
                                        
                                        {item?.isnewtest == 0  ? "btn btn-soft-danger w-100" : 
                                        "btn btn-soft-danger w-50"
                                         }
                                        // "btn btn-soft-danger w-50"
                                        >
                                          Resume
                                        </button>

                                        {
item?.isnewtest == 1 ?
                                          <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                            New test
                                          </button> : ''
                                        }
                                        </div>


                                      ) : null}

                                      {item.testid == null ? (
                                        <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-100">
                                          Start Test
                                        </button>
                                      ) : null}
                                    </div>

                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                          {/* <Paginations
                                                        // perPageData={perPageData}
                                                        // data={Subject}
                                                        // currentPage={currentPage}
                                                        // setCurrentPage={setCurrentPage}
                                                        // isShowingPageLength={true}
                                                        // paginationDiv="col-sm-12 col-md-7"
                                                        // paginationClass="pagination justify-content-end pagination-rounded"
                                                    /> */}
                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>
                </CardBody> :

                <div className="imgalignn1o">

                  <img src={layout2} alt="Girl in a jacket" width="450" height="450"></img>
                </div>
            }

          </Col>
        }
      </Row>

    </React.Fragment >
  );
};

export default withRouter(SubjectContent);
