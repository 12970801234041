//Base URL

// export const BASE_URL = "http://172.16.1.201:8086/api/";
///  export const BASE_URL = "http://localhost:8087/api/";
// export const BASE_URL = "https://icestudentsapi.trio-s.com/api/";
export const BASE_URL = "https://stdapi.iiceacademy.in/api/"
//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";