import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinners from "../../components/Common/Spinner";
import { useNavigate } from 'react-router-dom'
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import './test.css'
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, Modal, ModalHeader, ModalBody, Card, CardBody, Badge } from "reactstrap";

import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
//i18n
import { withTranslation } from "react-i18next";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

const OnlineTest = (location) => {
  const data = useLocation();
  const hrRef = useRef(null);
  const minRef = useRef(null);
  const secRef = useRef(null);

  const store = useSelector(state => state.Blueprint)
  const [isLoading, setLoading] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(0)
  const [intervalId, setIntervalId] = useState(null);

  const [showResult, setShowResult] = useState(false)
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [apiseconds, setApiSeconds] = useState(false);
  const [playicon, setPlayIcon] = useState(false);
  const [pauseicon, setPauseIcon] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [timerStatusFlag, setTimerStatusFlag] = useState(false)

  const navigate = useNavigate();


  const [answeredCount, setAnsweredCount] = useState(0)
  const [markedCount, setMarkedCount] = useState(0)
  const [notAnsweredCount, setNotAnsweredCount] = useState(0)
  const [notAttendedCount, setNotAttendedCount] = useState(0)
  const [markedAnsweredCount, setMarkedAnsweredCount] = useState(0)
  const [statusflag, setStatusFlag] = useState(0)
  const [statusflag1, setStatusFlag1] = useState(0)

  const [examName, setExamName] = useState("")
  const [totalQuestion, setTotalQuestion] = useState("0")
  const [backflag, SetBlackFlag] = useState("0")
  const [maxMarks, setMaxMarks] = useState("0")

  const [paramsData, setParamsData] = useState({})

  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  })

  const toggleModal = () => {
    onClickNext(activeQuestion)
    setModalOpen(!modalOpen);
  };
  const toggleModal1 = () => {
    onClickNext(activeQuestion)
    setModalOpen2(!modalOpen2);
  };
  const toggleModal2 = () => {
    // onClickNext(activeQuestion)
    setModalOpen3(!modalOpen3);
  };

  useEffect(() => {

    const disableBackButton = (e) => {
      e.preventDefault(); // Prevent default behavior of back button
      window.history.forward(); // Move forward in history
    };
    // Push a new state to prevent going back
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href)
    }
    // Add an event listener for the popstate event
    // window.addEventListener('popstate', disableBackButton);

    // Cleanup function to remove event listener
    // return () => {
    //   window.removeEventListener('popstate', disableBackButton);
    // };
  }, []);

  const [questions, setQuestions] = useState([])
  const [testID, setTestID] = useState(0)


  const [counter, setCounter] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [key, setKey] = useState(0);

  const [totalCounter, setTotalCounter] = useState(0);
  const [remainingCounter, setRemainingCounter] = useState(0);
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setIsActive(false);
      return <div className="timer" style={{ fontSize: '12px' }}>Time's Up!</div>;
    }

    return (
      <div className="timer">
        <span>{formatTimehr(hours, true)}:{formatTime(minutes, false)}:{formatTimesec(seconds, false)}</span>
        {/* <div className="text">Time</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div> */}
      </div>
    );
  };
  useEffect(() => {
    let timer;
    if (isActive && counter > 0) {
      timer = setInterval(
        () => setCounter((prevCounter) => prevCounter - 1),
        1000
      );
    } else if (!isActive && counter !== 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [counter, isActive]);

  useEffect(() => {
    if (backflag == 0) {
      if (data?.state?.id != 0) {
        setParamsData(data)
        if (data?.state?.reattempt == 1 || data?.state?.resume == 1) {
          bindReattemptQuestions()
        }
        else {
          bindExamQuestions()
        }

      }
      else if (testID === 0) {
        navigate('/dashboard')
      } else {
      }
    }

    SetBlackFlag(1)
  }, [data, backflag])



  async function bindReattemptQuestions() {
    const user = getLoggedInUser()

    const params = {
      "stid": Number(user.UserInfo?.stid),
      "testid": Number(data?.state?.id),
      "flag": data?.state?.type,
      "type": data?.state?.reattempt == 1 ? 0 : 1,
      "cid": Number(data?.state?.cid)

    };


    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo?.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'exam/getReattemptDetails', params, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await bindReattemptQuestions();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        var qaList = response?.data?.body?.examqaodetailslist;
        setQuestions(qaList)
        qaList[activeQuestion]['attended'] = 1
        setNotAttendedCount(qaList.filter(e => e?.attended != 1)?.length);

        setAnsweredCount(qaList.filter(e => e?.answered == 1)?.length);
        setMarkedCount(qaList.filter(e => e?.marked == 1)?.length);
        setNotAnsweredCount(qaList.filter(e => e?.attended == 1 && (e?.answered != 1 && e?.marked != 1))?.length);

        var examDetails = response?.data?.body?.examDetails?.[0];
        setExamName(examDetails?.c_name)
        setMaxMarks(examDetails?.c_totalmarks)
        setTotalQuestion(examDetails?.c_totalquestions)
        setHours(examDetails?.hrs)
        setMinutes(examDetails?.mins)
        setSeconds(examDetails?.secs)
        setTestID(response?.data?.body?.testID)

        setCounter((Number(examDetails?.hrs) * 3600) + (Number(examDetails?.mins) * 60) + (Number(examDetails?.secs)))
        setTotalCounter((Number(examDetails?.defhrs) * 3600) + (Number(examDetails?.defmins) * 60) + (Number(examDetails?.secs)))

        setRemainingCounter((Number(examDetails?.hrs) * 3600) + (Number(examDetails?.mins) * 60) + (Number(examDetails?.secs)))


      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      //console.error("Change Password API Error:", error);
    } finally {

    }
  }


  async function bindExamQuestions() {
    const user = getLoggedInUser()
    setLoading(true)
    const params = {
      "stid": Number(user.UserInfo.stid),
      "cid": Number(data?.state?.id),
      "flag": data?.state?.type,
      "exlid": data?.state?.exlid
    };

    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo?.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/getModelExamDetails', params, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await bindExamQuestions();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        var qaList = response?.data?.body?.examqaodetailslist;
        setQuestions(qaList)

        qaList[activeQuestion]['attended'] = 1


        setNotAttendedCount(qaList.filter(e => e?.attended != 1)?.length);

        var examDetails = response?.data?.body?.examDetails?.[0];
        setExamName(examDetails?.c_name)
        setMaxMarks(examDetails?.c_totalmarks)
        setTotalQuestion(examDetails?.c_totalquestions)

        setHours(examDetails?.hrs)
        setMinutes(examDetails?.mins)

        setCounter((Number(examDetails?.hrs) * 3600) + (Number(examDetails?.mins) * 60))
        setTotalCounter((Number(examDetails?.hrs) * 3600) + (Number(examDetails?.mins) * 60))

        // setSeconds(examDetails?.secs)

        setTestID(response?.data?.body?.testID)


      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false)
    } catch (error) {
      // Handle error
      console.error("Change Password API Error:", error);
      navigate('/dashboard')
    } finally {

    }
  }

  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  // const [seconds, setSeconds] = useState(0);
  // const step = 1;
  // const loops = Math.round(100 / step);
  // const increment = 360 / loops;
  // const half = Math.round(loops / 2);
  // const barColor = '#ec366b';
  // const backColor = '#feeff4';

  // let interval;

  // useEffect(() => {


  //   startClock();
  //   return () => clearInterval(interval);

  // }, [hours, minutes, seconds]);


  // // const pauseClock = () => {
  // //   console.log('cloc', clockRunning)
  // //   if (clockRunning) {
  // //     console.log('clockrunning', clockRunning)
  // //     console.log('----', intervalId)
  // //     clearInterval(interval);
  // //     setClockRunning(false);
  // //     // return () => clearInterval(interval);

  // //   }
  // // };

  // const startClock = () => {
  //   console.log('clo', clockRunning)
  //   console.log('intervalid', intervalId)
  //   if (!clockRunning) { 
  //   interval = setInterval(() => {
  //     if (hours === 0 && minutes === 0 && seconds === 0) {
  //       console.log('======')
  //       // clearInterval(interval);
  //       setModalOpen1(!modalOpen1);
  //       setClockRunning(false);
  //       setTimeout(() => {

  //         submitTest()    
  //       }, 4000);
  //       return;
  //     }
  //     if (Number(seconds) === 0) {
  //       console.log('=-----')
  //       if (Number(minutes) === 0) {
  //         setHours((prevHours) => Number(prevHours) - 1);
  //         setMinutes(59);
  //       } else {
  //         setMinutes((prevMinutes) => Number(prevMinutes) - 1);
  //       }
  //       setSeconds(59);

  //     } else {
  //       console.log('-------->')
  //       setSeconds((prevSeconds) => Number(prevSeconds) - 1);
  //     }

  //     const pie = ((hours * 3600 + minutes * 60 + seconds) / (3 * 3600)) * 100;
  //     const i = Math.floor(pie);
  //     let nextDeg;

  //     if (i < half) {
  //       nextDeg = (90 + increment * i) + 'deg';
  //       document.querySelector('.clock').style.backgroundImage = `linear-gradient(90deg, rgb(144, 238, 144) 50%, transparent 50%, transparent), linear-gradient(${nextDeg}, rgb(211, 211, 211) 50%, rgb(144, 238, 144) 50%, rgb(144, 238, 144))`;
  //     } else {
  //       nextDeg = (-90 + increment * (i - half)) + 'deg';
  //       document.querySelector('.clock').style.backgroundImage = `linear-gradient(${nextDeg}, rgb(211, 211, 211) 50%, transparent 50%, transparent), linear-gradient(270deg, rgb(211, 211, 211) 50%, rgb(144, 238, 144) 50%, rgb(144, 238, 144))`;
  //     }
  //   }, 1000);
  //   setIntervalId(interval);
  // }

  // };
  // // const pauseClock = () => {
  // //   console.log('intervalid', intervalId)
  // //   clearInterval(intervalId);

  // // }
  // const pauseClock = () => {
  //   console.log('cl', clockRunning)
  //   console.log('cl',interval)
  //   if (!clockRunning) {
  //     clearInterval(interval);
  //     setClockRunning(true);
  //   }else{
  //     clearInterval(interval)
  //     setIntervalId(null)
  //     setClockRunning(true)
  //   }
  //   setClockRunning(false)
  //   return () => clearInterval(interval);
  // };
  // // const resumeClock = () => {
  // //   setClockRunning(false)
  // //   console.log('intere', intervalId)
  // //   setIntervalId(null)

  // //   startClock(); // Simply call startClock to resume the clock
  // // };

  // const formatTime = (time, isHour) => {
  //   if (isHour) {
  //     return time?.toString();
  //   } else {
  //     return Number(time) < 10 ? `0${Number(time).toString()}` : time;
  //   }
  // };


  const onClickNext = (index) => {
    saveExamDetails()




    setAnsweredCount(questions.filter(e => e?.answered == 1)?.length);
    // setMarkedCount(questions.filter(e => e?.marked == 1)?.length);
    setNotAnsweredCount(questions.filter(e => e?.attended == 1 && (e?.answered != 1 && e?.marked != 1))?.length);


    if (activeQuestion !== questions.length - 1) {

      setActiveQuestion(index)

      setNotAttendedCount(questions.filter(e => e?.attended != 1)?.length > 0 ? questions.filter(e => e?.attended != 1)?.length : 0);
      setQuestions(prevArray => {
        const newArray = [...prevArray]; // Create a copy of the original array  

        newArray[index]['attended'] = 1

        return newArray; // Return the new array
      });

    } else {
      setActiveQuestion(0)

    }
  }

  const onClickClear = () => {

    setQuestions(prevArray => {
      const newArray = [...prevArray]; // Create a copy of the original array

      for (var i = 0; i < newArray[activeQuestion]['optionList'].length; i++) {
        newArray[activeQuestion]['optionList'][i]['selectedanswer'] = 0
        newArray[activeQuestion]['optionList'][i]['qa_answerflag'] = 0
      }
      newArray[activeQuestion]['attended'] = 1
      newArray[activeQuestion]['answered'] = 0
      newArray[activeQuestion]['marked'] = 0


      return newArray; // Return the new array
    });

  }

  async function saveExamDetails() {
    const user = getLoggedInUser()

    var options = questions[activeQuestion]['optionList'];
    var selectedAns = options.find(e => e?.selectedanswer == 1);
    const data = {
      "stid": Number(user.UserInfo?.stid),
      "testid": Number(testID),
      "qid": Number(questions[activeQuestion]['qid']),
      "qaid": selectedAns?.qaid ? Number(selectedAns?.qaid) : 0,
      "reviewmark": questions[activeQuestion]['marked'] ? Number(questions[activeQuestion]['marked']) : 0
    };

    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo?.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'exam/saveOnlineExam', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await saveExamDetails();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {



      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      //console.error("Change Password API Error:", error);
    } finally {

    }
  }
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [clockRunning, setClockRunning] = useState(false);

  useEffect(() => {
    let intervalId;

    const startClock = () => {
      intervalId = setInterval(() => {
        if (hours === 0 && Number(minutes) === 0 && seconds === 0) {
          setTimerStatusFlag(false)
          clearInterval(intervalId);
          setClockRunning(false);
          // Optionally, perform actions when the timer reaches zero
          return;
        }
        if (Number(seconds) === 0) {
          if (Number(minutes) === 0) {
            setHours(prevHours => prevHours - 1);
            setMinutes(59);
          } else {
            setMinutes(prevMinutes => prevMinutes - 1);
          }
          setSeconds(59);
        } else {
          setSeconds(prevSeconds => prevSeconds - 1);
        }
      }, 1000);
      setClockRunning(true);
    };

    if (clockRunning) {
      startClock();
    } else {
      clearInterval(intervalId); // Clear the interval if not running
    }

    return () => clearInterval(intervalId);
  }, [hours, minutes, seconds, clockRunning]);


  useEffect(() => {

    startTimer()


    // setCounter()
  }, [hours, minutes, seconds])

  useEffect(() => {

    if (testID != 0) {
      if (Number(hours) === 0 && Number(minutes) === 0 && Number(seconds) === 0) {
        // setTimeout(() => {

        //   // setModalOpen1(!modalOpen1);
        // }, 1000)
        // setStatusFlag1(2)
        submitTest(2)
      }
    }

  }, [hours, minutes, seconds, testID])

  const startTimer = () => {

    setTimerStatusFlag(true)
    setClockRunning(true);
    setPauseIcon(true)
    setIsActive(true)
    setPlayIcon(false)
    // setModalOpen3(false)
    // setModalOpen2(false)
  };
  const startTimer1 = () => {

    setTimerStatusFlag(true)
    setClockRunning(true);
    setPauseIcon(true)
    setIsActive(true)
    setPlayIcon(false)
    setModalOpen3(false)
    setModalOpen2(false)
  };
  const gotoTest = () => {
    navigate('/dashboard')
  }

  const pauseTimer = () => {
    setModalOpen2(!modalOpen2)
    //  pauseTimer1()
  };
  const pauseTimer1 = () => {
    setTimerStatusFlag(false)
    setClockRunning(false);
    setPauseIcon(false)
    setPlayIcon(true)

    setIsActive(false)
    setModalOpen2(false)
    setModalOpen3(true)
  }

  const formatTime = (time) => {
    return Number(time) < 10 ? `0${Number(time)}` : time;
  };
  const formatTimesec = (time) => {
    return Number(time) < 10 ? `0${Number(time)}` : time;
  };


  useEffect(() => {
    const fetchData = async () => {

      const user = getLoggedInUser()
      var hrs = Number(hrRef.current.value) < 10 ? `0${Number(hrRef.current.value)}` : hrRef.current.value
      var sec = Number(secRef.current.value) < 10 ? `0${Number(secRef.current.value)}` : secRef.current.value
      var mns = Number(minRef.current.value) < 10 ? `0${Number(minRef.current.value)}` : minRef.current.value

      //   alert( hours+":"+minutes+":"+seconds
      // )
      //       var hrs = 1;
      //        var mns = 2;
      // var sec = 2;

      const data = {
        "testid": Number(testID),
        "statusflag": Number(statusflag),
        "timertext": hrs + ":" + mns + ":" + sec


      };

      const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo?.accessToken) } };

      try {
        const response = await axios.post(url.BASE_URL + 'exam/updateTestLogDetails', data, config);
        if (response && response?.data?.body?.statusFlag === 3) {
          toast.error(response.data.body.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
        }

        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();

          if (refresh) {
            if (Number(refresh) === constants.invalidRefreshToken) {
              logoutUser();
            } else {
              await fetchData();
            }
          }
        }

        if (Number(response?.data?.body?.statusFlag) === 1) {


          setStatusFlag(0)
        } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        }
      } catch (error) {
        // Handle error
        //console.error("Change Password API Error:", error);
      } finally {

      }


    }


    let intervalId;
    // Set interval to fetch data every 5 seconds
    if (timerStatusFlag) {
      intervalId = setInterval(fetchData, 5000);

    }


    return () => clearInterval(intervalId); // Cleanup the interval

  }, [testID, timerStatusFlag, statusflag]);

  useEffect(() => {
    var value = `${formatTimesec(seconds)}`
    setApiSeconds(value)
    if (value - 5) {

    }
  }, [seconds])

  const formatTimehr = (time) => {
    return Number(time) < 10 ? `${Number(time)}` : time;
  };

  // const formatTime = (time, isHour) => {
  //   if (isHour) {
  //     return time?.toString();
  //   } else {
  //     return Number(time) < 10 ? `0${Number(time).toString()}` : time;
  //   }
  // };
  const timerValue = `${formatTimehr(hours)}:${formatTime(minutes)}:${formatTimesec(seconds)}`;


  useEffect(() => {
    document.addEventListener("contextmenu", handlecontextmenu)

    const handleKeyDown = (event) => {

        if (event.code === "MetaLeft" && event.keyCode === 91 && event.metaKey === true) {
            event.preventDefault()
        }


        // Ctrl + A 
        if (event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
            // Prevent default behavior for Ctrl+A (select all)
            event.preventDefault();
        }
        // Ctrl + c
        if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
            // Prevent default behavior for Ctrl+C (copy)
            event.preventDefault();
        }
        // // Inspect disabled
        if (event.ctrlKey && event.shiftKey && (event.key == 'i' || event.key === 'I')) {
            event.preventDefault();
        }
        if ((event.ctrlKey || event.metaKey) && (event.keyCode === 91)) { // Ctrl + S or Command + S
            event.preventDefault();
            // alert('Screenshots are disabled.');
        }
        if (event.key === "Meta") {
            event.preventDefault();
            // alert('=-=-=')
        }
        if ((event.key === "PrintScreen" || event.key === 'Meta') && (event.keyCode === 44 || event.keyCode === 91)
            && event.metaKey === 'true') {
            event.preventDefault();
            // alert('=-=-=')
        }

    }
    window.addEventListener('keydown', handleKeyDown);
    // Add event listener to capture Ctrl+A and Ctrl+C keydown events
    document.addEventListener('keydown', handleKeyDown);

    // document.addEventListener('keyup', (e) => {
    // console.log('e=====>', e)
    //     if (e.key == 'PrintScreen') {
    //         navigator.clipboard.writeText('');
    //         alert('Screenshots disabled!');
    //     }
    //     if((e.metaKey) && (e.keyCode == 91)){
    //         navigator.clipboard.writeText('');

    //         alert('dis')
    //     }
    //     if (event.key === 's' && event.metaKey && event.shiftKey) {
    //         event.preventDefault(); // Prevent the default behavior
    //         console.log('Windows + Shift + S is restricted');
    //     }
    // });
    document.addEventListener('keydown', function (event) {
        // Check if the key combination is Windows + PrintScreen
        if (event.key === 'PrintScreen' && (event.metaKey || event.ctrlKey)) {
            event.preventDefault(); // Prevent the default behavior
            console.log('Windows + PrintScreen is restricted');
        }

        // Check if the key combination is Windows + Shift + S
        if (event.key === 's' && event.metaKey && event.shiftKey) {
            event.preventDefault(); // Prevent the default behavior
            console.log('Windows + Shift + S is restricted');
        }
    });

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
        // document.removeEventListener("contextmenu",handlecontextmenu)
        document.removeEventListener('keydown', handleKeyDown);
    }


}, [])

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     return false
  //     event.returnValue = ''; // Some browsers require a return value to display the confirmation prompt
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   document.addEventListener("contextmenu", handlecontextmenu)

  //   const handleKeyDown = (event) => {

  //     if (event.code === "MetaLeft" && event.keyCode === 91 && event.metaKey === true) {
  //       event.preventDefault()
  //     }


  //     // Ctrl + A 
  //     if (event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
  //       // Prevent default behavior for Ctrl+A (select all)
  //       event.preventDefault();
  //     }
  //     // Ctrl + c
  //     if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
  //       // Prevent default behavior for Ctrl+C (copy)
  //       event.preventDefault();
  //     }
  //     // // Inspect disabled
  //     if (event.ctrlKey && event.shiftKey && (event.key == 'i' || event.key === 'I')) {
  //       event.preventDefault();
  //     }
  //     if ((event.ctrlKey || event.metaKey) && (event.keyCode === 91)) { // Ctrl + S or Command + S
  //       event.preventDefault();
  //     }
  //     if (event.key === "Meta") {
  //       event.preventDefault();
  //     }
  //     if ((event.key === "PrintScreen" || event.key === 'Meta') && (event.keyCode === 44 || event.keyCode === 91)
  //       && event.metaKey === 'true') {
  //       event.preventDefault();
  //     }

  //   }
  //   window.addEventListener('keydown', handleKeyDown);
  //   // Add event listener to capture Ctrl+A and Ctrl+C keydown events
  //   document.addEventListener('keydown', handleKeyDown);

  //   document.addEventListener('keydown', function (event) {
  //     // Check if the key combination is Windows + PrintScreen
  //     if (event.key === 'PrintScreen' && (event.metaKey || event.ctrlKey)) {
  //       event.preventDefault(); // Prevent the default behavior
  //     }

  //     // Check if the key combination is Windows + Shift + S
  //     if (event.key === 's' && event.metaKey && event.shiftKey) {
  //       event.preventDefault(); // Prevent the default behavior
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //     // document.removeEventListener("contextmenu",handlecontextmenu)
  //     document.removeEventListener('keydown', handleKeyDown);
  //   }


  // }, [])

  const handlecontextmenu = (e) => {
    e.preventDefault()
  }

  const handleDragStart = (e) => {
    e.preventDefault(); // Prevent default drag behavior
  };
  async function submitTest(stflag) {
    const user = getLoggedInUser()
    if (questions && questions.length > 0) {
      var options = questions[activeQuestion]['optionList'];

    }
    var selectedAns = options.find(e => e?.selectedanswer == 1);
    const data = {
      "stid": Number(user.UserInfo?.stid),
      // "stid": 50,
      "testid": Number(testID)

    };

    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo?.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'exam/submitOnlineExam', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await saveExamDetails();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        // toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        // navigate(
        //   `/summary`
        // )
        if (testID && (stflag === 1)) {
          setTimerStatusFlag(false)
          navigate('/summary', { state: { testid: Number(testID) } })
        } else if (testID && (stflag === 2)) {

          setTimerStatusFlag(false)
          // navigate('/summary', { state: { testid: Number(testID) } })
          setModalOpen1(!modalOpen1);
        } else if (testID && (stflag === 3)) {
          setTimerStatusFlag(false)
          navigate('/summary', { state: { testid: Number(testID) } })
        }
        //  else {
        //   navigate("/dashboard")
        // }
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      //console.error("Change Password API Error:", error);
    } finally {

    }
  }

  const markForReview = () => {
    // setMarkedCount(questions.filter(e => e?.marked == 1)?.length);
    setQuestions(prevArray => {
      const newArray = [...prevArray]; // Create a copy of the original array


      newArray[activeQuestion]['marked'] = 1
      return newArray; // Return the new array
    });
  }
  useEffect(() => {
    setMarkedCount(questions.filter(e => e?.marked == 1)?.length);
    setAnsweredCount(questions.filter(e => e?.answered == 1)?.length);
    setNotAttendedCount(questions.filter(e => e?.attended != 1)?.length);
    setNotAnsweredCount(questions.filter(e => e?.attended == 1 && (e?.answered != 1 && e?.marked != 1))?.length);
  }, [questions])


  const onClickPrevious = () => {


    if (activeQuestion !== 0) {
      setActiveQuestion(prev => prev - 1);
    } else {
      setActiveQuestion(questions.length - 1);
    }
  };
  const onClickSubmit = () => {
    navigate("/login")
  };

  const onAnswerSelected = (answer, index) => {

    // setNotAnsweredCount(questions.filter(e => e?.attended == 1 && (e?.answered != 1 && e?.marked != 1))?.length);

    // setNotAttendedCount(questions.filter(e => e?.attended != 1)?.length > 0 ? questions.filter(e => e?.attended != 1)?.length : 0);
    setQuestions(prevArray => {
      const newArray = [...prevArray]; // Create a copy of the original array

      for (var i = 0; i < newArray[activeQuestion]['optionList'].length; i++) {
        newArray[activeQuestion]['optionList'][i]['selectedanswer'] = 0
      }
      newArray[activeQuestion]['optionList'][index]['selectedanswer'] = 1

      newArray[activeQuestion]['answered'] = 1
      return newArray; // Return the new array
    });
    // onClickNext(index)

    // questions[activeQuestion]['optionList'][index]['selectedanswer'] = 1
  }

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

  //meta title
  // document.title = "Masters | Online Test";


  return (
    <React.Fragment>

      <div className="page-content pt-1"
      // onCopy={(event) => event.preventDefault()} onDragStart={handleDragStart} onKeyDown={(event) => {
      //   event.preventDefault();
      // }}
      >
        <Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Card>
              <CardBody>
                <Row>
                  <Col lg="6">
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{examName}</h5>
                      </div>
                      <div className="text-muted">
                        <h8>
                          Total Questions
                          <span className="m-2">
                            <Badge className="btn btn-blue custom-badge">
                              <b>{totalQuestion}</b>
                            </Badge>
                          </span>
                        </h8>
                        <h8>
                          Max Score
                          <span className="m-2">
                            <Badge className="btn btn-success custom-badge">
                              <b>{maxMarks}</b>
                            </Badge>
                          </span>
                        </h8>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <Row>
                      <Col>
                        <div className="activeques">
                          <span className="active-question-no">{addLeadingZero(activeQuestion + 1)}</span>
                          <span className="total-question">/{addLeadingZero(questions.length)}</span>
                        </div>
                      </Col>

                      <Col style={{ textAlign: "end", zIndex: 9999 }}>
                        <span className="count1"> {playicon ? <div className="align123"
                          onClick={() => {
                            // setStatusFlag(2)
                            startTimer()
                          }}>

                          <i className="fas fa-play-circle"></i>
                        </div>

                          // <Button onClick={startTimer}>Start</Button> 
                          : pauseicon ?
                            <div className="align123" onClick={() => {

                              // setStatusFlag(1)
                              pauseTimer()
                            }
                            }>

                              <i className="fas fa-pause-circle"></i>
                            </div>

                            // <Button onClick={pauseTimer}>Pause</Button> 
                            : ""}</span>
                      </Col>
                      {/* <Col>
                      <span className="count1"> {playicon ? <div className="align123"
                              onClick={() => {
                                // setStatusFlag(2)
                                startTimer()
                              }}>

                              <i className="fas fa-play-circle"></i>
                            </div>

                              // <Button onClick={startTimer}>Start</Button> 
                              : pauseicon ?
                                <div className="align123" onClick={() => {

                                  // setStatusFlag(1)
                                  pauseTimer()
                                }
                                }>

                                  <i className="fas fa-pause-circle"></i>
                                </div>

                                // <Button onClick={pauseTimer}>Pause</Button> 
                                : ""}</span>
                      </Col> */}
                      {/* <Col>
                          
                          {playicon ? <div className="align123"
                            onClick={startTimer}>

<i className="fas fa-play-circle"></i>
                          </div>
                          
                          // <Button onClick={startTimer}>Start</Button> 
                          : pauseicon ?
                          <div className="align123" onClick={pauseTimer}>

                            <i className="fas fa-pause-circle"></i>
                          </div>

                          // <Button onClick={pauseTimer}>Pause</Button> 
                          : ""}</Col> */}
                      <Col>

                        <div className="row">
                          {/* <p>oooo</p> */}

                          <div key={key}
                            id="pomodoro-timer" className="Progressbar">
                            {/* <p>iii</p> */}
                            <CountdownCircleTimer
                              onComplete={() => setIsActive(false)}
                              isPlaying={isActive}
                              duration={Number(totalCounter)}
                              colors={"#34c38f"}
                              initialRemainingTime={Number(counter)}
                            >
                              {renderTime}
                            </CountdownCircleTimer>

                          </div>
                          {/* <p>oooo</p> */}
                          {/* <div>
                            <span className="count1"> {playicon ? <div className="align123"
                              onClick={() => {
                                // setStatusFlag(2)
                                startTimer()
                              }}>

                              <i className="fas fa-play-circle"></i>
                            </div>

                              // <Button onClick={startTimer}>Start</Button> 
                              : pauseicon ?
                                <div className="align123" onClick={() => {

                                  // setStatusFlag(1)
                                  pauseTimer()
                                }
                                }>

                                  <i className="fas fa-pause-circle"></i>
                                </div>

                                // <Button onClick={pauseTimer}>Pause</Button> 
                                : ""}</span>
                            </div> */}

                          {/* <div className="clock-wrap">
                          <div className="clock pro-0"> */}


                          {/* <span className="count">{formatTimehr(hours, true)}:{formatTime(minutes, false)}:{formatTimesec(seconds, false)}</span> */}
                          <input
                            type="hidden"
                            ref={hrRef}
                            value={hours}
                          />
                          <input
                            type="hidden"
                            value={minutes}
                            ref={minRef}
                          />     <input
                            type="hidden"
                            value={seconds}
                            ref={secRef}
                          />


                          {/* </div>
                        </div> */}
                          <div>

                            {/* <Button onClick={startTimer}>Start</Button>
      <Button onClick={pauseTimer}>Pause</Button> */}
                          </div>
                          {/* <div className="App"> */}
                          {/* <div className="timer">{timerValue}</div>
      <div className="progress-bar">
        <div className="progress" style={{ transform: `rotate(${(clockRunning ? ((hours * 3600 + minutes * 60 + seconds) / (3 * 3600)) : 0) * 360}deg)` }}></div>
      </div>
      <button onClick={startTimer}>Start</button>
      <button onClick={pauseTimer}>Pause</button>

    </div> */}


                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div style={{ borderTop: '1px solid grey', margin: '8px' }}></div>
                <Row>
                  <Col xs="12">
                    <Row>

                      {/* <CardBody className="mini-stats-wid"> */}
                      <div>
                        <Badge pill color="success" className="me-1">
                          <b>{answeredCount}</b> - Answered
                        </Badge>
                        <Badge pill color="btn btn-yellow custom-badge" className="me-1 btn btn-yellow custom-badge">
                          <b>{markedCount}</b> - Marked
                        </Badge>

                        <Badge pill color="btn btn-blue custom-badge" className="me-1 btn btn-blue custom-badge">
                          <b>{notAnsweredCount}</b> -  Not Answered
                        </Badge>
                        <Badge pill color="btn btn-lightblue custom-badge" className="me-1 btn btn-lightblue custom-badge" >
                          <b>{notAttendedCount}</b> - Not Attended

                        </Badge>
                      </div>
                      {/* </CardBody> */}

                    </Row>
                  </Col>
                </Row>

                <Row>

                  <Col xs="12" lg="8">
                    <div className="quiz-container" >
                      {!showResult ? (
                        <div className="m-4">
                          <div className="cls-div-qn">
                            {questions[activeQuestion]?.qt_contentflag == 1 ? (
                              <div className='ck-content' dangerouslySetInnerHTML={{ __html: questions[activeQuestion]?.qt_text }} />
                            ) : (
                              <h2>{questions[activeQuestion]?.qt_text}</h2>
                            )}
                            <ul>
                              {questions[activeQuestion]?.optionList?.map((answer, index) => (

                                <div className="row" key={answer} >
                                  <li
                                    className={answer?.selectedanswer === 1 ? 'selected-answer col-11 li-option' : 'col-11 li-option'}
                                    style={{ cursor: 'pointer' }}
                                    // onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgreen'}
                                    // onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                    onClick={() => onAnswerSelected(answer, index)}
                                  >
                                    <input
                                      type="radio"
                                      name="answer"
                                      style={{ verticalAlign: 'middle' }}
                                      value={answer?.qaid}
                                      onChange={() => onAnswerSelected(answer, index)}
                                      checked={answer?.selectedanswer === 1}
                                    />
                                    {answer.qa_contentflag == 1 ? (
                                      <div className='ck-content' dangerouslySetInnerHTML={{ __html: answer.qa_name }} />
                                    ) : (
                                      <span> {answer.qa_name}</span>
                                    )}
                                  </li>
                                </div>
                              ))}
                            </ul>

                          </div>
                          <div style={{ justifyContent: 'right' }}>


                            <div onClick={() => onClickClear()} className="btn btn-soft-primary clrresponse">Clear Response</div>

                            {activeQuestion === 0 ? <div style={{ display: 'hidden', visibility: 'hidden', opacity: '0' }} onClick={onClickPrevious} className="btn btn-soft-primary">Previous</div> : <div onClick={onClickPrevious} className="btn btn-soft-primary">Previous</div>}




                            <div onClick={() => markForReview()} className="btn btn-soft-warning">Mark for Review</div>


                            {activeQuestion === questions.length - 1 ? "" : <div onClick={() => onClickNext(activeQuestion + 1)} className="btn btn-soft-primary">Next</div>}

                          </div>
                        </div>
                      ) : (
                        <div className="result">
                          <h3>Result</h3>
                          <p>
                            Total Question: <span>{questions.length}</span>
                          </p>
                          <p>
                            Total Score:<span> {result.score}</span>
                          </p>
                          <p>
                            Correct Answers:<span> {result.correctAnswers}</span>
                          </p>
                          <p>
                            Wrong Answers:<span> {result.wrongAnswers}</span>
                          </p>
                        </div>
                      )}
                    </div>

                  </Col>
                  <Col xs="12" lg="4">
                    <Card style={{ width: '100%', height: 'calc(62vh - 100px)', overflowY: 'auto', padding: '20px', marginTop: '10px' }}>
                      <Row>
                        {questions?.map((e, index) => (

                          activeQuestion + 1 == index + 1 ? (
                            <div key={"button_" + index} style={{ width: '10%', background: 'rgb(164 174 229 / 40%)', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }} className="cursor-focus" >
                              {index + 1}
                            </div>
                          )
                            :

                            e.attended == 1 && e.answered != 1 && e.marked != 1 && activeQuestion + 1 != index + 1 ? (
                              <div key={"button_" + index} style={{ width: '10%', background: 'blue', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }} className="cursor-focus" onClick={() => onClickNext(index)}>
                                {index + 1}
                              </div>
                            ) : e.attended == 1 && e.answered == 1 && e.marked != 1 ? (
                              <div key={"button_" + index} style={{ width: '10%', background: '#34c38f', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }} className="cursor-focus" onClick={() => onClickNext(index)}>
                                {index + 1}
                              </div>
                            ) : e.marked == 1 ? (
                              <div key={"button_" + index} style={{ width: '10%', background: 'orange', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }} className="cursor-focus" onClick={() => onClickNext(index)}>
                                {index + 1}
                              </div>
                            ) : (
                              <div key={"button_" + index} style={{ width: '10%', background: '#f0f2fd66', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000' }} className="cursor-focus

" onClick={() => onClickNext(index)}>
                                {index + 1}
                              </div>
                            )


                        ))}
                      </Row>
                    </Card>


                    <Button className="btn btn-silk custom-badge position-absolute bottom-0 end-0" style={{ marginBottom: '1px' }} onClick={toggleModal}>
                      Submit Test
                    </Button>
                    <Modal isOpen={modalOpen} toggle={toggleModal} centered>
                      <ModalHeader toggle={toggleModal}>Exam Summary</ModalHeader>
                      <ModalBody>
                        <div className="row">
                          <div className="col-md-4 text-end">
                            <p><strong>Exam Name:</strong></p>
                            <p><strong>No. of Questions:</strong></p>
                            <p><strong>Answered:</strong></p>
                            <p><strong>Marked For Review:</strong></p>
                            <p><strong>Not Answered:</strong></p>
                            {/* <p><strong>Marked & Answered:</strong></p> */}
                            <p><strong>Not Attended:</strong></p>
                          </div>
                          <div className="col-md-8">
                            <p className="no-wrap">{examName}</p>
                            <p>{totalQuestion}</p>
                            <p>{answeredCount}</p>
                            <p>{markedCount}</p>
                            <p>{notAnsweredCount}</p>
                            {/* <p>{markedAnsweredCount}</p> */}
                            <p>{notAttendedCount}</p>
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          <h5>Are you sure want to submit?</h5>
                          <div className="d-flex justify-content-center">
                            <Button color="primary" onClick={() => {
                              // setStatusFlag1(1)
                              submitTest(1)
                            }} className="me-2 modalbtn">Yes</Button>
                            <Button color="secondary" onClick={toggleModal} className="modalbtn">No</Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                    <Modal isOpen={modalOpen1} centered>
                      <ModalHeader>Time's up!</ModalHeader>
                      <ModalBody>
                        <p>Your time is up. This will let you auto submit the exam!</p>

                        <div className="mt-3">
                          <div className="text-end">
                            <Button color="danger" onClick={() => {
                              submitTest(3)
                            }} className="me-2 modalbtn">Close</Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                    <Modal isOpen={modalOpen2} toggle={toggleModal1} centered backdrop="static">
                      <ModalHeader toggle={toggleModal1}>Confirmation</ModalHeader>
                      <ModalBody>

                        <div className="text-center mt-3">
                          <h5>Are you sure want to pause this test?</h5>
                          <div className="d-flex justify-content-center">
                            <Button color="primary" onClick={() => {
                              pauseTimer1()
                            }} className="me-2 modalbtn">Yes</Button>
                            <Button color="secondary" onClick={() => {
                              setModalOpen2(false)
                            }} className="modalbtn">No</Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                    <Modal isOpen={modalOpen3} toggle={toggleModal2} centered backdrop="static">
                      <ModalHeader >Exam Summary</ModalHeader>
                      <ModalBody>
                        <div className="row">
                          <div className="col-md-4 text-end">
                            <p><strong>Exam Name:</strong></p>
                            <p><strong>No. of Questions:</strong></p>
                            <p><strong>Answered:</strong></p>
                            <p><strong>Marked For Review:</strong></p>
                            <p><strong>Not Answered:</strong></p>
                            {/* <p><strong>Marked & Answered:</strong></p> */}
                            <p><strong>Not Attended:</strong></p>
                          </div>
                          <div className="col-md-8">
                            <p className="no-wrap">{examName}</p>
                            <p>{totalQuestion}</p>
                            <p>{answeredCount}</p>
                            <p>{markedCount}</p>
                            <p>{notAnsweredCount}</p>
                            {/* <p>{markedAnsweredCount}</p> */}
                            <p>{notAttendedCount}</p>
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          {/* <h5>Are you sure want to submit?</h5> */}
                          <div className="d-flex justify-content-center">
                            <Button color="primary" onClick={() => {
                              startTimer1()
                            }} className="me-2 modalbtn">Resume test</Button>
                            <Button color="secondary" onClick={gotoTest} className="modalbtn">Go to Home</Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>

                  </Col>
                </Row>

              </CardBody>
            </Card>
          )}
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
OnlineTest.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default OnlineTest;
