import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Collapse
} from "reactstrap";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";

//Import Breadcrumb
import EBBreadcrumbs from "../../components/Common/EBBreadcrumbs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
//redux
import { useDispatch } from "react-redux";
import Spinners from "../../components/Common/Spinner";
import layout2 from "../../assets/images/nodatafound.jpg";

import './smstyle.css'

const StudyMaterial = () => {
  //meta title
  const dispatch = useDispatch();
  const data = useLocation();

  document.title = "StudyMaterials | ICE - Student Portal";
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true)
  const [params1, setParams] = useState([])


  const user = getLoggedInUser()
  const navigate = useNavigate();


  const [boardCategoryList, setBoardCategoryList] = useState([])
  const [titleList, setTitleList] = useState([])

  const [topicfilterList, setfilterTopicList] = useState([])


  const [topicList, setTopicList] = useState([

  ])
  const [fulltopiclist, setFullTopicList] = useState([

  ])
  const colorArray = [
    { "badge_color": "text-bg-info", "bg_color": "btn-soft-info" },
    { "badge_color": "text-bg-warning", "bg_color": "btn-soft-warning" },
    { "badge_color": "text-bg-success", "bg_color": "btn-soft-success" },
    { "badge_color": "text-bg-secondary", "bg_color": "btn-soft-secondary" },
    { "badge_color": "text-bg-primary", "bg_color": "btn-soft-primary" },
    { "badge_color": "text-bg-dark", "bg_color": "btn-soft-dark" },
  ]

  const [activeGroupName, setActiveGroupName] = useState("");
  const [activeSubjectName, setActiveSubjectName] = useState("");
  const [activePaperName, setActivePaperName] = useState("");
  const [activeSubjectID, setActiveSubjectID] = useState(0);
  const [activePaperID, setActivePaperID] = useState(0);
  const [activeTopicID, setActiveTopicID] = useState(0);
  const [loadStatus, setLoadStatus] = useState(true);

  useEffect(() => {
    const data = localStorage.getItem('authUser')
    const parsevalue = JSON.parse(localStorage.getItem('authUser'))
    if (parsevalue?.UserInfo?.stu_interested[0]?.mstid == 1) {
      fetchData();

    }
     else {
      navigate('/dashboard')
    }

  }, [dispatch]);


  function onClickSubject(r) {
    setIsOpen(!isOpen)
    setActiveSubjectID(r.sno)
    const params = {
      "st_sid": Number(r.sid) || 0,
      "st_expid": Number(r.expid),
      "st_exctid": Number(r.exctid),
      "stid": Number(user.UserInfo.stid),
    }
    setTopicList(params)
    setParams(Number(user.UserInfo.stid))
    listStudyMaterialSubjectList(params);

  }

  function onClickTopic(r) {
    navigate('/studymaterialview', {
      state: {
        sty_stid: Number(r.styid), st_stsid: Number(r.st_stsid), stid: Number(user.UserInfo.stid), st_tid: Number(r.st_tid), examboardcategory: r.exct_name, exp_name: r.exp_name, s_name: r.s_name, t_name: r.t_name, exctid: Number(r.st_exctid), expid: Number(r.st_expid),
        sid: Number(r.st_sid), st_title: r.st_title, sno: activeSubjectID
      }
    })

  }


  const handleSearch = (e) => {
    const value = e.toLowerCase()
    if (value) {
      const filtered_topic = fulltopiclist.filter(e => e.t_name.toLowerCase().includes(value))
      setTopicList(filtered_topic)
    } else {
      setTopicList(topicfilterList)
    }
  }
  
  const fetchData = async () => {
    setLoading(true)
    var boardcatArr = [];
    const data = {
      stid: Number(user.UserInfo.stid),
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/getStudyMaterialSubjectList', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await fetchData();
          }
        }
      }
      if (response?.data?.body?.studymaterialsubjectlist) {
        boardcatArr = response.data.body.studymaterialsubjectlist;
        bindMenuArray(boardcatArr)

      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student detailsAPI Error:", error);
    } finally {

    }
  }
  const listStudyMaterialSubjectList = async (params) => {
    setLoading(true)
    var boardcatArr = [];
    var titleArr = [];

    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/getStudyMaterialTopicList', params, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await listStudyMaterialSubjectList(params);
          }
        }
      }
      if (response?.data?.body?.studymateriallist) {
        boardcatArr = response.data.body.studymateriallist;
        titleArr = response.data.body.studymaterialtitlelist

        bindMenuArray1(boardcatArr, titleArr)

      } else {
        // toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student detailsAPI Error:", error);
    } finally {

    }
  }


  const bindMenuArray = (boardcatArr) => {
    if (loadStatus && boardcatArr && boardcatArr.length > 0) {
      setActivePaperID(boardcatArr[0].expid)
      var categoryObj = {}
      var result = boardcatArr.reduce(function (r, el) {
        var exctid = el.exctid;
        if (!categoryObj[exctid]) {
          categoryObj[exctid] = {
            exctid: el.exctid,
            examboardcategory: el.examboardcategory,
            isopen: false,
            exampaper: []
          }
          r.push(categoryObj[exctid]);
        }
        var obj = {
          sno : el.sno,
          exctid: el.exctid,
          expid: el.expid,
          exp_name: el.exp_name,
          isopen: false,
          sid: el.sid,
          examboardcategory: el.examboardcategory,
          s_name: el.s_name,
          t_count: el.t_count
        }
        categoryObj[exctid].exampaper.push(obj);

        return r;
      }, [])

      var examdetailsarray = []
      for (var i = 0; i < result.length; i++) {
        var subObj = {}
        var j = 0;
        var subjectresult = result[i].exampaper.reduce(function (r, el) {
          j = j + 1;
          var expid = el.expid;
          if (!subObj[expid]) {
            subObj[expid] = {
              sno : el.sno,
              exctid: el.exctid,
              expid: el.expid,
              exp_name: el.exp_name,
              examboardcategory: el.examboardcategory,
              isopen: j === 1 ? true : false,
              subjectList: []
            }
            r.push(subObj[expid]);
          }
          var obj = {
            sno : el.sno,
            exctid: el.exctid,
            expid: el.expid,
            exp_name: el.exp_name,
            isopen: j === 1 ? true : false,
            sid: el.sid,
            examboardcategory: el.examboardcategory,
            exp_name: el.exp_name,
            s_name: el.s_name,
            t_count: el.t_count
          }
          subObj[expid].subjectList.push(obj);
          return r;
        }, [])
        var finalobj = {
          exctid: result[i].exctid,
          examboardcategory: result[i].examboardcategory,
          isopen: i == 0 ? true : false,
          exampaper: subjectresult
        }
        examdetailsarray.push(finalobj);
      }
      setBoardCategoryList(examdetailsarray)
      setActiveSubjectID(examdetailsarray[0].exampaper[0].subjectList[0].sno)
let params;
if(data?.state?.statusflag === 1){
   params = {
    "st_sid": Number(data?.state?.subjectid),
    "st_expid": Number(data?.state?.exampaperid),
    "st_exctid": Number(data?.state?.Examcatid),
    "stid": Number(user.UserInfo.stid)
  }
  const boardCategoryList_arr = Object.assign([], examdetailsarray)
  if (boardCategoryList_arr.length > 0) {
    var activeCategory = boardCategoryList_arr.map(e => {
      if (e?.exctid == data?.state?.Examcatid) {
        e['isopen'] = true
        e.exampaper.map(f => {
          if (Number(f.exctid) == Number(data?.state?.Examcatid) && Number(f.expid) == Number(data?.state?.exampaperid)) {
            f['isopen'] = true
            setActiveSubjectID(data?.state?.sno)
          }
          else {
            f['isopen'] = false
          }
        })
      }
      else {
        e['isopen'] = false

        e.exampaper.map(f => {
          f['isopen'] = false
        })
      }
      return e
    })
    setBoardCategoryList(activeCategory)
  }

  // boardCategoryList_arr.forEach(i => {
  //   const examPaperLisArr = Object.assign([], i.exampaper)
  //   const result1 = []
  //   const obj = { ...i }
  //   examPaperLisArr.forEach(j => {

  //     const obj1 = { ...j }
  //     if ((Number(j.exctid) === data?.state?.Examcatid) && (Number(j.expid) === data?.state?.exampaperid)) {
  //       if (j.isopen === false) {
  //         obj1['isopen'] = true
  //       } else {
  //         obj1['isopen'] = false
  //         // setActivePaperName(row.exp_name);
  //         // setActiveSubjectName(row.subjectList[0].s_name);
  //       }
  //       setActiveSubjectID(data?.state?.sno)
  //       setActivePaperID(data?.state?.exampaperid)
  //       setActiveGroupName(data?.state?.examboardcategory);
  //       setActiveSubjectName(data?.state?.s_name);
  //     }
  //     result1.push(obj1)
  //     subresult.push(obj1)
  //   })
  //   obj['exampaper'] = result1
  //   result.push(obj)
  //   setBoardCategoryList(result)
  // })
  setActiveSubjectID(data?.state?.sno)
  setActivePaperID(data?.state?.exampaperid)
  setActiveGroupName(data?.state?.examboardcategory);
  setActiveSubjectName(data?.state?.s_name);
// console.log('board', boardCategoryList)
//   const boardCategoryList_arr = Object.assign([], examdetailsarray)
//   const result = []
//   const subresult = []
//   console.log('vvvvv', boardCategoryList_arr)
//   boardCategoryList_arr.forEach(i => {
//     const examPaperLisArr = Object.assign([], i.exampaper)
//     const result1 = []
//     const obj = { ...i }
//     examPaperLisArr.forEach(j => {

//       const obj1 = { ...j }
//       if (j.exctid === data?.state?.Examcatid && j.expid === data?.state?.exampaperid) {
//         if (j.isopen) {
//           obj1['isopen'] = false
//         } else {
//           obj1['isopen'] = true
//           setActivePaperName(data?.state?.exampaper);
//           setActiveSubjectName(data?.state?.subjectname);
//         }
//       }
//       result1.push(obj1)
//       subresult.push(obj1)
//     })
//     obj['exampaper'] = result1
//     result.push(obj)
//     console.log('resuele', result)
//     setBoardCategoryList(result)
//   })
  
}else{
   params = {
    "st_sid": Number(examdetailsarray[0].exampaper[0].subjectList[0].sid),
    "st_expid": Number(examdetailsarray[0].exampaper[0].expid),
    "st_exctid": Number(examdetailsarray[0].exampaper[0].exctid),
    "stid": Number(user.UserInfo.stid)
  }
}

      // const params = {
      //   "st_sid": Number(examdetailsarray[0].exampaper[0].subjectList[0].sid),
      //   "st_expid": Number(examdetailsarray[0].exampaper[0].expid),
      //   "st_exctid": Number(examdetailsarray[0].exampaper[0].exctid),
      //   "stid": Number(user.UserInfo.stid)
      // }
      listStudyMaterialSubjectList(params)
      setActiveGroupName(examdetailsarray[0].examboardcategory);
      setActivePaperName(examdetailsarray[0].exampaper[0].exp_name);
      setActiveSubjectName(examdetailsarray[0].exampaper[0].subjectList[0].s_name);

      setLoadStatus(false)
    }
  }
  const bindMenuArray1 = (boardcatArr, titleArr) => {
    if (boardcatArr && boardcatArr.length > 0 && titleArr && titleArr.length > 0) {
      const topicArray = boardcatArr.map((e) => {
        let result = []
        for (let i = 0; i < titleArr.length; i++) {
          if (e.st_tid === titleArr[i].st_tid) {
            result.push(titleArr[i])
          }
        }
        e['titleArray'] = result;
        return e;
      })
      setTopicList(topicArray)
    }

    setParams(Number(user.UserInfo.stid))
    setTopicList(boardcatArr);
    setFullTopicList(boardcatArr)
    setTitleList(titleArr);
    setfilterTopicList(boardcatArr);

  }

  const getMenuContent = (item) => {
    setActiveSubjectID(item.sno)

    setActiveGroupName(item.examboardcategory);
    setActiveSubjectName(item.s_name);


  }
  const onClickExamPaper = (row) => {
    if (boardCategoryList && boardCategoryList.length > 0) {
      const boardCategoryList_arr = Object.assign([], boardCategoryList)
      const result = []
      const subresult = []
      boardCategoryList_arr.forEach(i => {
        const examPaperLisArr = Object.assign([], i.exampaper)
        const result1 = []
        const obj = { ...i }
        examPaperLisArr.forEach(j => {

          const obj1 = { ...j }
          if (j.exctid === row.exctid && j.expid === row.expid) {
            if (j.isopen) {
              obj1['isopen'] = false
            } else {
              obj1['isopen'] = true
              setActivePaperName(row.exp_name);
              setActiveSubjectName(row.subjectList[0].s_name);
            }
          }
          result1.push(obj1)
          subresult.push(obj1)
        })
        obj['exampaper'] = result1
        result.push(obj)
        setBoardCategoryList(result)
      })
    }
  }
  const onClickBoardCategory = (row) => {
    if (boardCategoryList && boardCategoryList.length > 0) {
      const boardCategoryList_arr = Object.assign([], boardCategoryList)
      const result = []
      boardCategoryList_arr.forEach(i => {
        const obj = { ...i }
        if (i.exctid === row.exctid) {
          if (row.isopen) {
            obj['isopen'] = false
          } else {
            obj['isopen'] = true
          }
        } else {
          obj['isopen'] = false
        }
        result.push(obj)
        setBoardCategoryList(result)
      })
    }
  }
  const onClickTopics = (row) => {
    var data = row.st_tid
    setActiveTopicID(data)

    if (topicList && topicList.length > 0) {
      const topicList_arr = Object.assign([], topicList)
      const result = []
      topicList_arr.forEach(i => {
        const obj = { ...i }
        if (i.st_tid === row.st_tid) {
          if (row.isopen) {
            obj['isopen'] = false
          } else {
            obj['isopen'] = true
          }
        } else {
          obj['isopen'] = false
        }
        result.push(obj)
        setTopicList(result)
      })
    }
  }


  return (
    <React.Fragment>
      <div className="page-content pt-1">
        <Container fluid>
          <EBBreadcrumbs title={activeGroupName} breadcrumbItem={activeSubjectName} breadcrumPaper={activePaperName} breadcrumbTitle="Study Material" />
          {/* Render Breadcrumbs */}

          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} /> :
              <Col xs="12">
                {/* Render Email SideBar */}
                {boardCategoryList && boardCategoryList.length > 0 ?

                  <Card className="email-leftbar">
                    <Button type="button" color="danger" block>Exam Board Categories</Button>
                    <div className="mail-list mt-1">
                      <ul className="list-unstyled categories-list">
                        {
                          (boardCategoryList || []).map((item, index) => (
                            <li key={index}>
                              <div className="custom-accordion">
                                <Link
                                  className="text-body fw-medium py-1 d-flex align-items-center"
                                  onClick={() => {
                                    onClickBoardCategory(item)
                                  }}
                                  to="#"
                                >
                                  <i className="bx bx-book-open smgroup me-2"></i>{item.examboardcategory}

                                  <i
                                    className={
                                      item.isopen
                                        ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                        : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                    }
                                  />
                                </Link>
                                <Collapse isOpen={item.isopen}>
                                  <div className="card border-0 shadow-none ps-2 mb-0">
                                    <ul className="list-unstyled mb-0">
                                      {
                                        (item.exampaper || [])?.map((f, e_index) => (

                                          <li key={e_index}>
                                            <div className="custom-accordion">

                                            
                                              <Link
                                                className="text-body fw-medium py-1 d-flex align-items-center font-size-11"
                                                onClick={() => {
                                                  setActivePaperID(f.expid)
                                                  onClickExamPaper(f)
                                                }}
                                                to="#"
                                              >
                                                {f.exp_name}
                                                <i
                                                  className={
                                                    f.isopen
                                                      ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                                      : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                  }
                                                />
                                              </Link>
                                              <Collapse isOpen={f.isopen}>
                                                <div className="card border-0 shadow-none ps-2 mb-0">
                                                  <ul className="list-unstyled mb-0 subject">
                                                    {
                                                      (f.subjectList || [])?.map((t, s_index) => (

                                                        <li className={activeSubjectID === t.sno && f.isopen ? 'subject-active' : 'subject-li'} key={s_index} onClick={() => {
                                                          // getMenuContent(t)

                                                        }}>
                                                          <Link to="#" className="d-flex align-items-center font-size-12 sub_li"
                                                            onClick={() => {
                                                              onClickSubject(t)
                                                            }}
                                                          >
                                                            <span className="me-auto">{t.s_name}</span>
                                                            {t.t_count && <span className="badge bg-success rounded-pill p-1 ms-2">{t.t_count}</span>}
                                                          </Link>
                                                        </li>
                                                      ))
                                                    }
                                                  </ul>
                                                </div>
                                              </Collapse>
                                            </div>
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  </div>
                                </Collapse>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>



                  </Card> :


                  <Card>

                    <div className="imgalignno">

                      <img src={layout2} alt="Girl in a jacket" width="400" height="400"></img>
                    </div>
                  </Card>
                }


                <div className="email-rightbar mb-3">

                  <Row className="px-3">
                    <Card>
                    {(boardCategoryList && boardCategoryList.length > 0) ?
                      <div className="px-3 pt-2">
                        <div className="d-sm-flex align-items-center justify-content-between">

                          <h4 className="mb-sm-0 font-size-18">Topics</h4>

                          <div className="search-box mb-2 me-2 align-items-center">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control search-box me-2 mb-2 d-inline-block rounded"
                                placeholder="Search..." onChange={(e) => handleSearch(e.target.value)} />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                          :null}

                      {((boardCategoryList && boardCategoryList.length > 0) && (topicList && topicList.length > 0))?

                        <div className="mail-list mt-1">
                          <ul className="list-unstyled categories-list">
                            <Row className="px-3">

                              {
                                (topicList || []).map((item, index) => (

                                  <Col xl={6} key={index}>
                                    <li key={index}>


                                      <div className="custom-accordion" style={{ border: "10px" }}>
                                        <div style={{ cursor: 'pointer', width: "100%" }}

                                          className={`px-3 py-1 rounded bg-opacity-50 d-block mb-1 ${colorArray[Math.floor(Math.random() * colorArray.length)].bg_color}`}>
                                          <Link
                                            className="text-body fw-medium py-1 d-flex align-items-center"

                                            onClick={() => {
                                              setActiveTopicID(item?.st_tid)
                                              onClickTopics(item)
                                            }}
                                            to="#"
                                          >
                                            <Col md={10}>

                                              {item.t_name}
                                            </Col>
                                            <Col md="2" xs="4">
                                              <span className="badge bg-success rounded-pill p-1 ms-2 float-end"> {" "}{item.file_count}</span>

                                            </Col>

                                            <i
                                              className={
                                                item.isopen
                                                  ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                                  : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                              }
                                            />

                                          </Link>
                                        </div>

                                        <Collapse isOpen={item.isopen}>
                                          <div className="card border-0 shadow-none mb-0">
                                            <ul className="list-unstyled mb-0 subject">
                                              {
                                                (item.titleArray || [])?.map((t, s_index) => (
                                                  <Col xl={12} key={s_index}>

                                                    <li className={activeTopicID === t.st_tid && item.isopen ? 'subject-active' : 'subject-li'} key={s_index} onClick={() => {
                                                      getMenuContent(t)
                                                    }}>
                                                      <div to="#" className="d-flex align-items-center font-size-11 sub_li"
                                                        onClick={() => {
                                                          onClickTopic(t)
                                                        }}
                                                      >
                                                        <Col md={11}>

                                                          <span className="me-auto" style={{ cursor: 'pointer' }}>{t.st_title}</span>
                                                        </Col>
                                                        {t.file_count &&
                                                          <Col md={2}>

                                                            <span className="badge bg-light smbadge"
                                                            ><i className="mdi mdi-folder font-size-12 text-warning"></i>{" "}{t.file_count}</span>
                                                          </Col>

                                                        }

                                                      </div>
                                                    </li>

                                                  </Col>
                                                ))
                                              }
                                            </ul>
                                          </div>
                                        </Collapse>
                                      </div>

                                    </li>
                                  </Col>

                                ))
                              }
                            </Row>
                          </ul>
                        </div>

                        :null}
                          {((boardCategoryList && boardCategoryList.length > 0) && (topicList && topicList.length == 0))?
                        <Card>

                        <div className="imgalignno">
    
                          <img src={layout2} alt="Girl in a jacket" width="400" height="400"></img>
                        </div>
                      </Card>:null

                      }
                    </Card>

                  </Row>


                </div>
              </Col>}

          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(StudyMaterial);
